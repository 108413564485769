export type BaseBoxPictureProps = {
  src: string,
  onClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => any,
  className?: string,

  topLeft?: JSX.Element | null,
  topRight?: JSX.Element | null,
  bottomLeft?: JSX.Element | null,
  bottomRight?: JSX.Element | null,

  topLeftOnClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => any | null,
  topRightOnClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => any | null,
  bottomLeftOnClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => any | null,
  bottomRightOnClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => any | null,

  topLeftClassName?: string,
  topRightClassName?: string,
  bottomLeftClassName?: string,
  bottomRightClassName?: string,
}

function BaseBoxPicture({ src, onClick, className, topLeft, topRight, bottomLeft, bottomRight, topLeftOnClick, topRightOnClick, bottomLeftOnClick,
  bottomRightOnClick, topLeftClassName, topRightClassName, bottomLeftClassName, bottomRightClassName }: BaseBoxPictureProps) {
  const floaterCSS = `absolute block w-7 h-7 sm:w-7 sm:h-7 md:w-8 md:h-8 transform rounded-full bg-white hover:cursor-pointer`;
  return (
    <div className="w-full mx-auto flex justify-center my-3">
      <span className="inline-block relative">
        <img
          className={`aspect-square rounded-lg border-2 border-primary-300 bg-white cursor-pointer ${onClick ? 'hover:cursor-pointer' : ''} ${className || ''}`}
          src={src}
          onClick={onClick}
          alt=""
        />

        {topLeft && (
          <span
            onClick={topLeftOnClick}
            className={`${floaterCSS} absolute top-0 left-0 flex items-center justify-center transform -translate-y-1/2 -translate-x-1/2 ${topLeftOnClick ? 'hover:cursor-pointer' : ''} ${topLeftClassName || ''}`}>
            <div className="w-10/12 h-10/12 mx-auto align-middle text-white">
              {topLeft}
            </div>
          </span>
        )}
        {topRight && (
          <span
            onClick={topRightOnClick}
            className={`${floaterCSS} absolute top-0 right-0 flex items-center justify-center transform -translate-y-1/2 translate-x-1/2 ${topRightOnClick ? 'hover:cursor-pointer' : ''} ${topRightClassName || ''}`}>
            <div className="w-10/12 h-10/12 mx-auto align-middle text-white">
              {topRight}
            </div>
          </span>
        )}
        {bottomLeft && (
          <span
            onClick={bottomLeftOnClick}
            className={`${floaterCSS} absolute bottom-0 left-0 flex items-center justify-center transform translate-y-1/2 -translate-x-1/2 ${bottomLeftOnClick ? 'hover:cursor-pointer' : ''} ${bottomLeftClassName || ''}`}>
            <div className="w-10/12 h-10/12 mx-auto align-middle text-white">
              {bottomLeft}
            </div>
          </span>
        )}
        {bottomRight && (
          <span
            onClick={bottomLeftOnClick}
            className={`${floaterCSS} absolute bottom-0 right-0 flex items-center justify-center transform translate-y-1/2 translate-x-1/2 ${bottomRightOnClick ? 'hover:cursor-pointer' : ''} ${bottomRightClassName || ''}`}>
            <div className="w-10/12 h-10/12 mx-auto align-middle text-white">
              {bottomRight}
            </div>
          </span>
        )}
      </span>
    </div>
  );
}

export default BaseBoxPicture;
