import axios from "axios";
import { useState, useContext, useEffect } from "react";
import GameBorder from "../components/GameBorder";
import HomeScreenButton from "../components/HomeScreenButton";
import { useNavigate, useParams } from "react-router-dom";
import { GameStatus } from "../models/GameState";
import { MessageProviderContext } from "../providers/MessageProvider";
import { Helmet } from "react-helmet";
import { noteError } from "../utils/logger";
import { PlayerUIDContext } from "../providers/PlayerUidProvider";
import ConfirmModal from "../components/modals/ConfirmModal";

function JoinGame() {
  let { gameCode: linkGameCode } = useParams();
  let [gameCode, setGameCode] = useState<string>(linkGameCode || "");
  let [showConfirmJoin, setShowConfirmJoin] = useState(false);
  let { addMessage, clearMessages } = useContext(MessageProviderContext);
  let uid = useContext(PlayerUIDContext);
  let navigate = useNavigate();

  let checkValidGameCode = () => {
    axios
      .get("/api/isValidGameCode", {
        params: {
          gameCode: gameCode.trim().toUpperCase(),
          userId: uid,
        },
      })
      .then((res) => {
        let data = res.data as {
          valid: boolean;
          gameStatus?: string;
          inGame?: boolean;
        };
        if (data.valid) {
          if (
            data.inGame ||
            data.gameStatus === GameStatus.LobbyWaiting ||
            data.gameStatus === GameStatus.LobbyCountdown
          ) {
            navigate(`/game/${gameCode.trim().toUpperCase()}`);
          } else {
            setShowConfirmJoin(true);
          }
        } else {
          noteError("Invalid Game Code");
          addMessage("It doesn't look like that's a valid game code.");
        }
      })
      .catch((err) => {
        noteError(err.message);
        addMessage(err.message);
        addMessage(
          "There was an error checking the Pic&Split Game Servers. Please try again later."
        );
      });
  };

  let formSubmit = (e: any) => {
    e.preventDefault();
    checkValidGameCode();
  };

  useEffect(() => {
    localStorage.removeItem("picAndSplitDoneEditing");
  }, []);

  return (
    <GameBorder
      background="hamburger-background"
      foreground="hamburger-foreground"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Join Game - Pic&amp;Split</title>
      </Helmet>
      <ConfirmModal
        open={showConfirmJoin}
        buttonTitle="Join In Progress"
        message="This game has already started, are you sure you'd like to join?"
        onConfirm={() => navigate(`/game/${gameCode.trim().toUpperCase()}`)}
        onClose={() => setShowConfirmJoin(false)}
      />
      <h3 className="text-5xl pt-5 font-cursive text-center text-gray-900">
        Join Game
      </h3>
      <div className="flex flex-col items-center mx-2 sm:mx-5">
        <div className="sm:px-2 pt-5">
          <h3 className="text-xl md:text-2xl">
            Make sure not to join on incognito!
          </h3>
          <form onSubmit={formSubmit}>
            <label htmlFor="gameCode" className="text-3xl text-gray-800">
              Game Code:
            </label>
            <input
              type="text"
              name="gameCode"
              value={gameCode}
              onChange={(e) => {
                setGameCode(e.target.value);
                clearMessages();
              }}
              autoFocus
              className="flex-1 block w-full min-w-0 rounded-md text-2xl sm:text-3xl p-1 sm:p-2 align-middle bg-white border-primary-300 border-2 pt-3"
            />
          </form>
        </div>
        <HomeScreenButton
          disabled={gameCode.trim().length === 0}
          onClick={checkValidGameCode}
          className="my-10"
        >
          Join
        </HomeScreenButton>
        <HomeScreenButton href="/" className="my-10">
          Back
        </HomeScreenButton>
      </div>
    </GameBorder>
  );
}

export default JoinGame;
