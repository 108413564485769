import React, { useState, useEffect, useContext } from "react";
import GameBorder from "../components/GameBorder";
import { PlayerUIDContext } from "../providers/PlayerUidProvider";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AxiosError, AxiosResponse } from "axios";
import { noteError } from "../utils/logger";
import { MessageProviderContext } from "../providers/MessageProvider";
import GameQRCodeModal from "../components/modals/GameQRCodeModal";
import HomeScreenButton from "../components/HomeScreenButton";
import axios from "axios";

function CreateGame() {
  let [gameQRCode, setGameQRCode] = useState("");
  let [gameCode, setGameCode] = useState("");

  let uidContext = useContext(PlayerUIDContext);
  let navigate = useNavigate();
  let { addMessage } = useContext(MessageProviderContext);

  let createGame = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // store in the database
    axios
      .post("/api/createGame", {
        adminId: uidContext,
      })
      .then((res: AxiosResponse) => {
        let data = res.data as { gameCode: string; gameLinkQRCode: string };

        if (!data.gameCode) {
          addMessage("Failed to create game");
          noteError("Data didn't have a gameCode");
          return;
        }

        if (data.gameLinkQRCode) {
          setGameQRCode(data.gameLinkQRCode);
          setGameCode(data.gameCode);
        } else {
          navigate(`/game/${data.gameCode}`);
        }
      })
      .catch((err: AxiosError) => {
        noteError(err);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <GameBorder
      background="hamburger-background"
      foreground="hamburger-foreground"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create Game - Pic&amp;Split</title>
      </Helmet>
      <GameQRCodeModal
        open={!!gameQRCode}
        onClose={() => navigate(`/game/${gameCode}`)}
        qrcodeLink={gameQRCode}
        gameCode={gameCode}
      />
      <h3 className="text-5xl pt-5 font-cursive text-center text-gray-900">
        Create New Game
      </h3>
      <h1 className="text-3xl md:text-4xl text-bold text-gray-900 text-center m-5">
        Pic&amp;Split is a photo scavenger hunt game with your friends!
      </h1>
      <div className="px-5 md:px-10">
        <h2 className="text-2xl md:text-3xl mt-5">
          Tips to have a great experience:
        </h2>
        <ul className="text-xl md:text-2xl space-y-2 list-disc ml-10">
          <li>
            Choose a place with lots of good photo opportunities, like a museum,
            park, or other location your group is familiar with
          </li>
          <li>
            Tell your friends to try to not use incognito or refresh the page
            during the game
          </li>
          <li>Send feedback to corban@corbanha.com</li>
          <li>Have fun!</li>
        </ul>
      </div>
      <div className="flex flex-col items-center text-center pt-5">
        <HomeScreenButton onClick={createGame}>Create Game!</HomeScreenButton>
        <HomeScreenButton href="/" className="my-10">
          Back
        </HomeScreenButton>
      </div>
    </GameBorder>
  );
}

export default CreateGame;
