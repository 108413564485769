import { useState, useEffect, useContext } from "react";
import GameBorder from "../components/GameBorder";
import HighScore from "../models/HighScore";
import HighScorePicture from "../models/HighScorePicture";
import HomeScreenButton from "../components/HomeScreenButton";
import ViewPhotoModal from "../components/modals/ViewPhotoModal";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { MessageProviderContext } from "../providers/MessageProvider";
import { Helmet } from "react-helmet";
import { noteError } from "../utils/logger";
import { AwardStatus } from "../models/SubmittedPicture";
import MediumBoxPicture from "../components/boxPictures/MediumBoxPicture";
import SmallBoxPicture from "../components/boxPictures/SmallBoxPicture";
import { PlayerUIDContext } from "../providers/PlayerUidProvider";
import HighScoreSubmittedPicture from "../models/HighScoreSubmittedPicture";

type ViewingPhoto = {
  url: string;
  collectedBy?: string;
  collectedByPictureUrl?: string;
  collectedByLabel?: string;
};

const HighScoreState = {
  TrophyInstructions: "TrophyInstructions",
  GoodTrophies: "GoodTrophies",
  BadTrophies: "BadTrophies",
  HighScores: "HighScores",
  DownloadPics: "DownloadPics",
};

const { Gold, Silver, Bronze, Trash } = AwardStatus;

function HighScores() {
  let [viewingPhoto, setViewingPhoto] = useState<ViewingPhoto | undefined>(
    undefined
  );
  let [highScores, setHighScores] = useState<HighScore[]>([]);

  let [highScorePictures, setHighScorePictures] = useState<HighScorePicture[]>(
    []
  );
  let [downloadPictures, setDownloadPictures] = useState<HighScorePicture[]>(
    []
  );

  let [additionalTrophyPoints, setAdditionalTrophyPoints] = useState(0);
  let [displayAdditionalTrophyPoints, setDisplayAdditionalTrophyPoints] =
    useState(0);
  let [goodTrophyPictures, setGoodTrophyPictures] = useState<
    HighScoreSubmittedPicture[]
  >([]);
  let [badTrophyPictures, setBadTrophyPictures] = useState<
    HighScoreSubmittedPicture[]
  >([]);

  let [highScoreState, setHighScoreState] = useState<string>(
    HighScoreState.HighScores
  );
  const { addMessage } = useContext(MessageProviderContext);

  let { gameCode } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();
  const uidContext = useContext(PlayerUIDContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [highScoreState]);

  useEffect(() => {
    if (!gameCode) {
      addMessage(
        "Invalid link, there should be a game code in the URL after /highScores/"
      );
      noteError("Highscores Page - Invalid Game Link");
    } else {
      axios
        .get("/api/getHighScores", {
          params: {
            gameCode,
          },
        })
        .then((res) => {
          let { highScores, highScorePictures } = res.data as {
            highScores: HighScore[];
            highScorePictures: HighScorePicture[];
          };
          setHighScores(highScores);
          let highScorePicturesModified = [...highScorePictures].filter(
            (x) => x.submissions.length !== 0
          );

          highScorePicturesModified.sort(
            (a, b) => b.submissions.length - a.submissions.length
          );
          if (highScorePicturesModified.length > 7) {
            highScorePicturesModified = highScorePicturesModified.slice(0, 7);
          }
          setHighScorePictures(highScorePicturesModified);

          if (
            searchParams.has("showTrophies") &&
            searchParams.get("showTrophies") === "true"
          ) {
            let goodTrophies: HighScoreSubmittedPicture[] = [];
            let badTrophies: HighScoreSubmittedPicture[] = [];
            highScorePictures.forEach((hsp) => {
              hsp.submissions.forEach((sub) => {
                console.log(sub);
                if (
                  sub.submittedById &&
                  uidContext.startsWith(sub.submittedById)
                ) {
                  if (
                    sub.awardStatus === Gold ||
                    sub.awardStatus === Silver ||
                    sub.awardStatus === Bronze
                  ) {
                    goodTrophies.push(sub);
                  } else if (sub.awardStatus === Trash) {
                    badTrophies.push(sub);
                  }
                }
              });
              setGoodTrophyPictures(goodTrophies);
              setBadTrophyPictures(badTrophies);
              console.log(goodTrophies);
              console.log(badTrophies);
              if (goodTrophies.length > 0 || badTrophies.length > 0) {
                setHighScoreState(HighScoreState.TrophyInstructions);
              }
            });

            // clean up url
            setSearchParams([]);
          }

          setDownloadPictures(highScorePictures);
        })
        .catch((err) => {
          console.error(err);
          if (err.response) {
            addMessage(err.response.data.error);
            noteError(err.response.data.error);
          } else {
            addMessage(err.message);
            noteError(err.message);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameCode, navigate]);

  useEffect(() => {
    let id: NodeJS.Timeout;

    let checkFunc = () => {
      id = setTimeout(() => {
        setDisplayAdditionalTrophyPoints((userScore) => {
          if (additionalTrophyPoints > userScore) {
            checkFunc();
            return Math.min(
              userScore +
                4 +
                Math.floor(
                  (additionalTrophyPoints *
                    0.1 *
                    (additionalTrophyPoints - userScore)) /
                    additionalTrophyPoints
                ),
              additionalTrophyPoints
            );
          } else if (additionalTrophyPoints < userScore) {
            checkFunc();
            return Math.max(
              userScore -
                4 -
                Math.floor(
                  (userScore * 0.1 * (userScore - additionalTrophyPoints)) /
                    userScore
                ),
              additionalTrophyPoints
            );
          }
          return userScore;
        });
      }, 50);
    };
    checkFunc();
    return () => clearTimeout(id);
  }, [additionalTrophyPoints]);

  useEffect(() => {
    if (
      highScoreState === HighScoreState.GoodTrophies ||
      highScoreState === HighScoreState.BadTrophies
    ) {
      let plusPoints = goodTrophyPictures.reduce((points, trophy) => {
        if (trophy.awardStatus === Gold) {
          return points + 50;
        } else if (trophy.awardStatus === Silver) {
          return points + 25;
        } else if (trophy.awardStatus === Bronze) {
          return points + 10;
        }
        return points;
      }, 0);

      if (highScoreState === HighScoreState.GoodTrophies) {
        setAdditionalTrophyPoints(plusPoints);
      } else if (highScoreState === HighScoreState.BadTrophies) {
        let badPoints = badTrophyPictures.reduce(
          (points, trophy) => points - trophy.score,
          0
        );
        setAdditionalTrophyPoints(plusPoints + badPoints);
      }
    }
  }, [highScoreState]);

  let getAwardStatusPhoto = (awardStatus: string | undefined) => {
    switch (awardStatus) {
      case Gold:
        return "/gold_trophy.jpg";
      case Silver:
        return "/silver_trophy.jpg";
      case Bronze:
        return "/bronze_trophy.jpg";
      case Trash:
        return "/poop_trophy.jpg";
      default:
        return "";
    }
  };

  let displayHighScorePictures = (pics: HighScorePicture[]) => {
    if (pics.length === 0) {
      return null;
    }

    return pics.map((highScorePicture, i) => {
      return (
        <div
          key={highScorePicture.id}
          className="flex flex-col items-center px-5 sm:px-10 py-5 my-5 border-b-2 border-gray-300"
        >
          <MediumBoxPicture
            src={highScorePicture.thumbnailUrl || highScorePicture.url}
            onClick={() => {
              highScoreState === HighScoreState.DownloadPics
                ? window.open(
                    highScorePicture.originalUrl || highScorePicture.url,
                    "_blank"
                  )
                : setViewingPhoto({
                    url: highScorePicture.url,
                    collectedBy: highScorePicture.collectedBy,
                    collectedByPictureUrl:
                      highScorePicture.collectedByPictureUrl,
                    collectedByLabel: "Submitted by:",
                  });
            }}
            topLeft={
              highScorePicture.collectedByPictureUrl ? (
                <img
                  className="h-full w-full rounded-full"
                  src={highScorePicture.collectedByPictureUrl}
                  alt=""
                />
              ) : null
            }
            topLeftClassName="border-2 border-primary-300"
          />
          {highScorePicture.submissions.length > 0 && (
            <>
              <h3 className="text-2xl sm:text-3xl text-left w-full mx-5 text-gray-700">
                Finds:
              </h3>
              <div className="grid grid-cols-2 px-2 mt-5 w-full">
                {highScorePicture.submissions.map((submission) => (
                  <SmallBoxPicture
                    key={submission.id}
                    src={submission.thumbnailUrl || submission.url}
                    onClick={() => {
                      highScoreState === HighScoreState.DownloadPics
                        ? window.open(
                            submission.originalUrl || submission.url,
                            "_blank"
                          )
                        : setViewingPhoto({
                            url: submission.url,
                            collectedBy: submission.submittedBy,
                            collectedByPictureUrl:
                              submission.submittedByPictureUrl,
                            collectedByLabel: "Found by:",
                          });
                    }}
                    topLeft={
                      submission.submittedByPictureUrl ? (
                        <img
                          className="h-full w-full rounded-full"
                          src={submission.submittedByPictureUrl}
                          alt=""
                        />
                      ) : null
                    }
                    topLeftClassName="border-2 border-primary-300"
                    topRight={
                      submission.awardStatus ? (
                        <img
                          className="h-full w-full rounded-full"
                          src={getAwardStatusPhoto(submission.awardStatus)}
                          alt=""
                        />
                      ) : null
                    }
                    topRightClassName="border-2 border-primary-300"
                  />
                ))}
              </div>
            </>
          )}
        </div>
      );
    });
  };

  let advancedHighScoreState = () => {
    if (highScoreState === HighScoreState.TrophyInstructions) {
      setHighScoreState(HighScoreState.GoodTrophies);
    } else if (highScoreState === HighScoreState.GoodTrophies) {
      setHighScoreState(HighScoreState.BadTrophies);
    } else if (highScoreState === HighScoreState.BadTrophies) {
      setHighScoreState(HighScoreState.HighScores);
    }
  };

  // TODO: should move all of these sub pages to different components
  if (highScorePictures.length === 0 && downloadPictures.length === 0) {
    // most likely an error or loading
    return (
      <GameBorder foreground="anchor-foreground" background="anchor-background">
        <Helmet>
          <meta charSet="utf-8" />
          <title>High Scores - Pic&amp;Split</title>
        </Helmet>
        <h3 className="text-6xl pt-5 font-cursive text-center text-gray-900">
          High Scores
        </h3>
        <div className="flex flex-col items-center text-center pt-5">
          <HomeScreenButton href="/createGame" hrefNewTab={true}>
            Create New Game
          </HomeScreenButton>
        </div>
      </GameBorder>
    );
  } else if (
    [
      HighScoreState.TrophyInstructions,
      HighScoreState.GoodTrophies,
      HighScoreState.BadTrophies,
    ].includes(highScoreState)
  ) {
    let viewingPhotos = goodTrophyPictures;
    if (highScoreState === HighScoreState.BadTrophies) {
      viewingPhotos = badTrophyPictures;
    }
    return (
      <GameBorder foreground="anchor-foreground" background="anchor-background">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Your Trophies - Pic&amp;Split</title>
        </Helmet>
        <h3 className="text-4xl md:text-6xl pt-5 font-cursive text-center text-gray-900">
          Your Trophies
        </h3>
        {highScoreState === HighScoreState.TrophyInstructions ? (
          <h3 className="text-3xl sm:text-4xl pt-10 text-center text-gray-900">
            Let's see your trophies!
          </h3>
        ) : (
          <div className="text-5xl sm:text-6xl pt-5 text-mono w-full text-center px-3 text-gray-900">
            {displayAdditionalTrophyPoints > 0 ? "+" : ""}
            {displayAdditionalTrophyPoints}
          </div>
        )}

        {highScoreState !== HighScoreState.TrophyInstructions && (
          <>
            {viewingPhotos.length === 0 && (
              <div className="text-4xl sm:text-5xl w-full text-center px-3 text-gray-900">
                {highScoreState === HighScoreState.GoodTrophies
                  ? "No Trophies :("
                  : "No poop emojis!"}
              </div>
            )}
            {viewingPhotos.length > 0 &&
              highScoreState === HighScoreState.BadTrophies && (
                <h3 className="text-2xl sm:text-3xl pt-10 text-center text-gray-900">
                  You got some poop!
                </h3>
              )}
            <div className="grid grid-cols-1 sm:grid-cols-2 px-2 mt-5">
              {viewingPhotos.map((picture) => (
                <MediumBoxPicture
                  key={picture.id}
                  src={picture.thumbnailUrl || picture.url}
                  onClick={() =>
                    setViewingPhoto({
                      url: picture.url,
                      collectedBy: picture.submittedBy,
                      collectedByPictureUrl: picture.submittedByPictureUrl,
                      collectedByLabel: "Submitted by:",
                    })
                  }
                  topLeft={
                    picture.submittedByPictureUrl ? (
                      <img
                        className="h-full w-full rounded-full"
                        src={picture.submittedByPictureUrl}
                        alt=""
                      />
                    ) : null
                  }
                  topLeftClassName="border-2 border-primary-300"
                  topRight={
                    picture.awardStatus ? (
                      <img
                        className="h-full w-full rounded-full"
                        src={getAwardStatusPhoto(picture.awardStatus)}
                        alt=""
                      />
                    ) : null
                  }
                  topRightClassName="border-2 border-primary-300"
                />
              ))}
            </div>
          </>
        )}

        <div className="flex flex-col items-center text-center pt-5">
          <HomeScreenButton onClick={advancedHighScoreState}>
            Continue
          </HomeScreenButton>
        </div>
      </GameBorder>
    );
  } else if (highScoreState === HighScoreState.DownloadPics) {
    return (
      <GameBorder foreground="anchor-foreground" background="anchor-background">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Download Pics - Pic&amp;Split</title>
        </Helmet>
        <h3 className="text-6xl pt-5 font-cursive text-center text-gray-900">
          Download Pics
        </h3>
        <div className="flex flex-col items-center text-center pt-5">
          <HomeScreenButton
            onClick={() => setHighScoreState(HighScoreState.HighScores)}
          >
            Back to High Scores
          </HomeScreenButton>
          <h3 className="text-3xl sm:text-4xl text-center pt-10">
            Click on a pic to download the full size
          </h3>
        </div>
        {displayHighScorePictures(downloadPictures)}
        <div className="flex flex-col items-center text-center pt-5">
          <HomeScreenButton
            onClick={() => setHighScoreState(HighScoreState.HighScores)}
          >
            Back to High Scores
          </HomeScreenButton>
        </div>
      </GameBorder>
    );
  }

  return (
    <GameBorder foreground="anchor-foreground" background="anchor-background">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Final Scores - Pic&amp;Split</title>
      </Helmet>
      <ViewPhotoModal
        open={!!viewingPhoto}
        onClose={() => setViewingPhoto(undefined)}
        imgSrc={viewingPhoto?.url || ""}
        collectedBy={viewingPhoto?.collectedBy}
        collectedByPictureUrl={viewingPhoto?.collectedByPictureUrl}
        collectedByLabel={viewingPhoto?.collectedByLabel}
      />
      <h3 className="text-6xl pt-5 font-cursive text-center text-gray-900">
        Final Scores!
      </h3>
      {highScores.map((highScore) => (
        <div
          className="flex flex-row justify-between items-center mx-5 mt-5 px-2 sm:px-5"
          key={highScore.userId}
        >
          <div className="flex flex-row items-center">
            <img
              className="w-16 h-16 sm:w-20 sm:h-20 rounded-full border-2 bg-white border-primary-300"
              src={highScore.pictureUrl}
              alt="avatar"
            />
            <h3 className=" text-3xl sm:text-4xl ml-3 text-center text-gray-900">
              {highScore.name}
            </h3>
          </div>
          <h3 className=" text-3xl sm:text-4xl text-center text-gray-900">
            {highScore.score}
          </h3>
        </div>
      ))}

      <div className="flex flex-col items-center text-center pt-5">
        <HomeScreenButton href="/createGame" hrefNewTab={true}>
          Create New Game
        </HomeScreenButton>
      </div>
      {highScorePictures.length > 0 && (
        <>
          <h3 className="text-4xl sm:text-5xl pt-10 text-center text-gray-900">
            Some of our favs:
          </h3>
          {displayHighScorePictures(highScorePictures)}
        </>
      )}
      <div className="flex flex-col items-center text-center pt-5">
        <HomeScreenButton
          onClick={() => setHighScoreState(HighScoreState.DownloadPics)}
        >
          <ArrowDownTrayIcon className="inline-block mr-2 w-7 h-7 md:w-10 md:h-10" />
          See All Pics
        </HomeScreenButton>
        <div className="text-2xl sm:text-3xl text-primary-300 pt-5">
          Currently game data and pics only stored for ~6 hours. Click to
          download the full size pictures
        </div>
      </div>
    </GameBorder>
  );
}

export default HighScores;
