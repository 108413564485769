import GameBorder from "../components/GameBorder";
import HomeScreenButton from "../components/HomeScreenButton";

function HomePage() {
  return (
    <GameBorder
      background="hamburger-background"
      foreground="hamburger-foreground"
    >
      <div className="w-full mx-auto">
        <div className="text-center px-5">
          <p className="text-5xl pt-5 font-cursive">
            The social group picture scavenger hunt game!
          </p>
        </div>
        <div className="flex flex-col items-center text-center pt-5">
          <HomeScreenButton href="/createGame">Create Game</HomeScreenButton>
          <HomeScreenButton href="/joinGame">Join Game</HomeScreenButton>
        </div>
      </div>

      <div className="w-full mx-auto">
        <div className="text-center px-5">
          <p className="text-5xl pt-10 font-cursive">Hey You!</p>
          <p className="text-2xl pt-2 font-thin">
            We're turning this game into a real iPhone/Android app and we'd love
            your feedback!
          </p>
        </div>
        <div className="flex flex-col items-center text-center">
          <HomeScreenButton
            hrefNewTab
            href="https://docs.google.com/forms/d/e/1FAIpQLSf8D5Wf80LQX5aWoopTLDjOrLlI8sM_X_u0XXIbGYtGcjc1yQ/viewform?usp=sf_link"
          >
            Give Feedback
          </HomeScreenButton>
        </div>
      </div>

      <div className="text-left pt-28 pb-10 px-5 sm:px-7 w-full">
        <h1 className="text-5xl text-center">How's it work?</h1>
        <p className="text-2xl pt-5 font-thin">
          Pic&amp;Split works best in a large physical place like a school,
          park, or museum, but really anywhere will do! A Pic&amp;Split game
          works in 3 rounds:
        </p>
        <ol className="text-2xl list-decimal ml-5 pt-5 font-thin">
          <li>
            Each team breaks off and takes 5 photos, try to find sneaky or
            clever photos around the location!
          </li>
          <li>
            Then each team is assigned random photos which other teams took and
            they need to try and find as many as they can the fastest!
          </li>
          <li>
            Each team assigns trophies to the best photos found for their
            collected pictures, and a poop emoji for the worst photo!
          </li>
        </ol>
        <p className="text-2xl pt-5 font-thin">
          First team to find all of their assigned random photos wins!
        </p>
      </div>
    </GameBorder>
  );
}

export default HomePage;
