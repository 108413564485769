import UserInfo from "./UserInfo";
import CollectedPicture from "./CollectedPicture";
import SubmittedPicture from "./SubmittedPicture";
import HighScore from "./HighScore";
import HighScorePicture from "./HighScorePicture";
import JudgingPicture from "./JudgingPicture";

export const GameStatus = {
  LobbyWaiting: "LobbyWaiting",
  LobbyCountdown: "LobbyCountdown",
  RoundOne: "RoundOne",
  PostRoundOneHighScores: "PostRoundOneHighScores",
  RoundTwo: "RoundTwo",
  PostRoundTwoHighScores: "PostRoundTwoHighScores",
  RoundThree: "RoundThree",
  ReturnToGroup: "ReturnToGroup",
  FinalHighScores: "FinalHighScores",
};

type GameState = {
  gameLinkQRCode: string;
  gameStatus: string;
  gameCode: string;
  roundEndTimestamp: number;
  amAdmin: boolean;

  connectedUsers: UserInfo[];

  numCollectingPictures: number;

  myPictures: CollectedPicture[]; // pictures I collected for the game
  mySubmittedPictures: SubmittedPicture[]; // pictures I was assigned in round two
  submissionsToMyPictures: JudgingPicture[]; // my pictures again, but with other people's submissions

  highScores: HighScore[];
  highScorePictures: HighScorePicture[];
}

export default GameState;
