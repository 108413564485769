import GameState, { GameStatus } from "../models/GameState";
import GameStateDelta from "../models/GameStateDelta";

export const initialGameState: GameState = {
  gameStatus: GameStatus.LobbyWaiting,
  gameLinkQRCode: "",
  gameCode: "",
  roundEndTimestamp: 0,
  amAdmin: false,

  connectedUsers: [],

  numCollectingPictures: 5,

  myPictures: [],
  mySubmittedPictures: [],
  submissionsToMyPictures: [],

  highScores: [],
  highScorePictures: [],
};

export type GameStateReducerAction = {
  type: string;
  payload: GameStateDelta;
};

export default function gameStateReducer(state: GameState = initialGameState, action: GameStateReducerAction): GameState {
  switch (action.type) {
    case "game_state_concrete":
      return {
        ...initialGameState,
        ...action.payload,
      } as GameState;
    case "game_state_delta":
      return {
        ...state,
        ...action.payload,
      } as GameState;
    default:
      return state;
  }
}
