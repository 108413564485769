import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

type Props = {
  open: boolean;
  onClose: () => void;
  imgSrc: string;
  collectedBy?: string;
  collectedByPictureUrl?: string;
  collectedByLabel?: string;
};

function ViewPhotoModal({
  open,
  onClose,
  imgSrc,
  collectedBy,
  collectedByPictureUrl,
  collectedByLabel,
}: Props) {
  if (!imgSrc) {
    return null;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-20 inset-0 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="relative inline-block align-bottom md:pt-5 text-left transform transition-all"
              style={{ minWidth: "300px" }}
            >
              <div className="flex flex-row justify-between items-center">
                <h3 className="text-lg md:text-xl text-left text-white">
                  {collectedBy ? collectedByLabel || "Submitted by:" : ""}
                </h3>
                <XMarkIcon
                  className="text-white w-6 h-6 sm:w-8 sm:h-8 md:w-12 md:h-12 cursor-pointer"
                  onClick={onClose}
                />
              </div>
              {collectedBy && collectedByPictureUrl && (
                <div className="flex flex-col justify-left">
                  <div className="flex items-center justify-start mb-2">
                    <img
                      className="w-12 h-12 md:w-16 md:h-16 flex-shrink-0 rounded-full border-2 border-primary-300"
                      src={collectedByPictureUrl}
                      alt=""
                    />
                    <div className="ml-2 md:ml-3 text-xl md:text-2xl text-white">
                      {collectedBy}
                    </div>
                  </div>
                </div>
              )}
              {imgSrc && (
                <img
                  className="w-100 mx-auto rounded-lg cursor-pointer border-2 border-primary-300"
                  src={imgSrc}
                  alt=""
                  onClick={onClose}
                  style={{ maxHeight: "80vh", maxWidth: "80vw" }}
                />
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ViewPhotoModal;
