import { useState, createContext } from "react";
import { v4 as uuidv4 } from 'uuid';

export enum MessageType {
  ERROR = "ERROR",
  INFO = "INFO",
  WARNING = "WARNING",
}

export type DisplayMessage = {
  id: string;
  message: string;
  type: MessageType,
  timeoutId?: NodeJS.Timeout;
}

export type MessageProviderContextType = {
  messages: DisplayMessage[];
  addMessage: (text: string, type?: MessageType) => void;
  removeMessage: (id: string) => void;
  clearMessages: () => void;
};

export const MessageProviderContext = createContext<MessageProviderContextType>({
  messages: [],
  addMessage: () => { },
  removeMessage: () => { },
  clearMessages: () => { },
});

function MessageProvider({ children }: { children: JSX.Element }) {
  const [messages, setMessages] = useState<DisplayMessage[]>([]);

  const addMessage = (text: string, type: MessageType = MessageType.ERROR) => {
    if (text.trim().length > 0) {
      setMessages(messages => {
        if (!messages.map(e => e.message).includes(text)) {
          // if we don't have a message with that text already
          let id = uuidv4();
          let timeoutId = setTimeout(() => {
            setMessages(messages => messages.filter(e => e.id !== id));
          }, type === MessageType.ERROR ? 10000 : 5000);

          if(type === MessageType.INFO){
            // don't want a spam of info messages
            messages = messages.filter(m => m.type !== MessageType.INFO);
          }
          return [{ id, message: text, type, timeoutId } as DisplayMessage, ...messages].slice(0, 3);
        } else {
          let existingMessage = messages.find(e => e.message === text);
          if (existingMessage) {
            if (existingMessage.timeoutId) {
              clearTimeout(existingMessage.timeoutId);
            }
            existingMessage.timeoutId = setTimeout(() => {
              setMessages(messages => messages.filter(e => e.id !== existingMessage?.id));
            }, type === MessageType.ERROR ? 10000 : 5000);

            // put it back on top as latest
            return [existingMessage, ...messages.filter(e => e.id !== existingMessage?.id)].slice(0, 3);
          }
        }
        return messages;
      });
    }
  };

  const removeMessage = (id: string) => {
    let message = messages.find(e => e.id === id);
    if (message) {
      if (message.timeoutId) clearTimeout(message.timeoutId);
      setMessages(messages => messages.filter(e => e.id !== id));
    }
  };

  const clearMessages = () => {
    setMessages([]);
  };

  return (
    <MessageProviderContext.Provider value={{
      messages,
      addMessage,
      removeMessage,
      clearMessages,
    }}>
      <>
        {children}
        <div className="w-full max-w-sm mx-auto fixed bottom-0 left-0 right-0" style={{ zIndex: 100 }}>
          {messages.map(msg => (
            <div key={msg.id} onClick={() => removeMessage(msg.id)}
              className={`p-3 m-5 border ${msg.type === MessageType.INFO ? 'border-tertiary-300' : 'border-primary-300'} bg-white ${msg.type === MessageType.INFO ? 'text-tertiary-300' : 'text-primary-300'} rounded-md cursor-pointer text-lg sm:text-xl transition-colors linear duration-1000`}>
              <h2 className={`${msg.type === MessageType.INFO ? 'text-tertiary-300' : 'text-primary-300'} font-medium text-lg sm:text-xl`}>{msg.type}</h2>
              {msg.message}
            </div>
          ))}
        </div>
      </>
    </MessageProviderContext.Provider>
  );
}

export default MessageProvider;
