import { useState } from "react";
import GameState, { GameStatus } from "../../models/GameState";
import {
  QrCodeIcon,
  ArrowLeftEndOnRectangleIcon,
} from "@heroicons/react/24/outline";
import GameQRCodeModal from "../modals/GameQRCodeModal";
import { useNavigate } from "react-router-dom";
import { Socket } from "socket.io-client";
import ConfirmModal from "../modals/ConfirmModal";

export default function LobbyTimerBar({
  gameState,
  socket,
}: {
  gameState: GameState;
  socket: Socket | undefined;
}) {
  let [showQRCode, setShowQRCode] = useState(false);
  let [showConfirmLeave, setShowConfirmLeave] = useState(false);

  let navigate = useNavigate();
  let logout = () => {
    if (socket) {
      socket.disconnect();
    }
    navigate(`/joinGame/${gameState.gameCode}`, { replace: true });
  };

  return (
    <div
      className="sticky top-0 z-10 sm:px-4 w-full flex flex-row justify-between items-center border-primary-300 rounded-b-xl bg-primary-300
    shadow-lg shadow-primary-700/60"
    >
      <GameQRCodeModal
        open={showQRCode}
        onClose={() => setShowQRCode(false)}
        qrcodeLink={gameState.gameLinkQRCode}
        gameCode={gameState.gameCode}
      />
      <ConfirmModal
        open={showConfirmLeave}
        buttonTitle="Leave"
        message="Do you really want to leave the game?"
        onClose={() => setShowConfirmLeave(false)}
        onConfirm={() => logout()}
      />
      <QrCodeIcon
        onClick={() => setShowQRCode(true)}
        className="m-5 w-12 h-12 text-white hover:cursor-pointer"
      />
      <div
        onClick={() => setShowQRCode(true)}
        className="flex flex-col justify-center text-center items-center hover:cursor-pointer"
      >
        <div className="text-white">
          {gameState.gameStatus === GameStatus.LobbyWaiting ? (
            <>
              <div className="text-2xl relative -left-12 leading-none pt-3">
                Game Code:
              </div>
              <div className="text-5xl md:text-6xl font-mono font-bold">
                {gameState.gameCode}
              </div>
            </>
          ) : (
            <>
              <div className="text-4xl md:text-5xl pt-2">Game Starting!</div>
            </>
          )}
        </div>
      </div>
      <ArrowLeftEndOnRectangleIcon
        onClick={() => setShowConfirmLeave(true)}
        className="m-5 w-12 h-12 text-white hover:cursor-pointer"
      />
    </div>
  );
}
