import BaseBoxPicture, { BaseBoxPictureProps } from "./BaseBoxPicture";

function MediumBoxPicture(args: BaseBoxPictureProps) {
  const floaterSize = "h-9 w-9 sm:h-10 sm:w-10";
  return <BaseBoxPicture
    {...args}
    className={`h-28 w-28 md:h-36 md:w-36 ${args.className || ''}`}
    topLeftClassName={`${floaterSize} ${args.topLeftClassName || ''}`}
    topRightClassName={`${floaterSize} ${args.topRightClassName || ''}`}
    bottomLeftClassName={`${floaterSize} ${args.bottomLeftClassName || ''}`}
    bottomRightClassName={`${floaterSize} ${args.bottomRightClassName || ''}`}
  />;
}

export default MediumBoxPicture;
