
type Props = {
  children: React.ReactNode,
  background: string,
  foreground: string,
  className?: string,
  logo?: boolean,
}

function GameBorder({ children, background, foreground, className, logo = true }: Props) {
  return (
    <div className={`w-full h-full hamburger-background transition-all ease-linear duration-300 ${background}`}>
      <div className={`flex flex-col items-center bg-white max-w-xl mx-auto shadow-xl shadow-gray-300 min-h-screen transition-all ease-linear duration-300 ${foreground}`}>
        {logo && <a href="/"><img src="/picAndSplitLogoVerticalBlack.png" alt="Pic&amp;Split" className="mt-10 max-h-32 md:max-h-40 mx-auto" /></a>}
        <div className={`w-full content-center pb-10 ${className || ""}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default GameBorder;
