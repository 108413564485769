import { Helmet } from "react-helmet";
import { Socket } from "socket.io-client";
import HomeScreenButton from "../components/HomeScreenButton";
import InstructionsScreenModal from "../components/modals/InstructionsScreenModal";
import GameState from "../models/GameState";


export default function ReturnToGroup({ gameState, socket }: { gameState: GameState, socket: Socket | undefined }) {

  return (
    <InstructionsScreenModal open={true} showSoundsGood={false} onClose={() => { }} title="Great Job!" subtitle={"Head back to your group's meeting place!"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Round One - Pic&amp;Split</title>
      </Helmet>
      {
        gameState.amAdmin && (
          <HomeScreenButton
            onClick={() => socket?.emit("group_together")}
          >
            Everyone's back!
          </HomeScreenButton>
        )
      }
    </InstructionsScreenModal>
  );
}
