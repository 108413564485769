import GameState from "../../models/GameState";
import { useState, useEffect, useContext } from "react";
import { PlayerUIDContext } from "../../providers/PlayerUidProvider";
import { CheckIcon } from "@heroicons/react/24/outline";
import { AwardStatus } from "../../models/SubmittedPicture";

type Props = {
  gameState: GameState;
  trophyPicture: string;
  assigningRound: string | null;
};

const { Gold, Silver, Bronze, Trash } = AwardStatus;

function JudgingPicturesTimerBar({
  gameState,
  trophyPicture,
  assigningRound,
}: Props) {
  let [topText, setTopText] = useState("");
  let uidContext = useContext(PlayerUIDContext);

  useEffect(() => {
    let generateTopText = () => {
      switch (assigningRound) {
        case Gold:
          return "Assign Gold";
        case Silver:
          return "Assign Silver";
        case Bronze:
          return "Assign Bronze";
        case Trash:
          return "Assign Poop";
        default:
          return "Done!";
      }
    };
    setTopText(generateTopText());
  }, [assigningRound]);

  return (
    <div className="sticky top-0 z-10 w-full text-center shadow-lg shadow-primary-700/80 rounded-b-xl transition-all ease-linear duration-300 bg-primary-300">
      <div className="text-white select-none flex flex-row justify-between items-center mx-5">
        <span className="h-16 w-16 text-5xl md:text-6xl font-mono font-bold py-2">
          {trophyPicture ? (
            <img
              src={trophyPicture}
              className="w-12 h-12 md:w-18 md:h-18 rounded-full"
              alt="Trophy"
            />
          ) : (
            <CheckIcon className="w-12 h-12 md:w-18 md:h-18" />
          )}
        </span>

        <span className="text-4xl md:text-5xl font-cursive font-thin">
          {topText}
        </span>

        <div className="flex justify-between space-x-5 items-center">
          <span className="text-5xl md:text-6xl font-mono font-bold">
            {gameState.connectedUsers.find((u) => u.id === uidContext)?.score ||
              0}
          </span>
        </div>
      </div>
    </div>
  );
}

export default JudgingPicturesTimerBar;
