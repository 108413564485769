import { useState, useContext, useEffect } from "react";
import GameBorder from "../components/GameBorder";
import { Socket } from "socket.io-client";
import GameState from "../models/GameState";
import {
  XMarkIcon,
  CheckIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import FileUploadModal from "../components/modals/FileUploadModal";
import ViewPhotoModal from "../components/modals/ViewPhotoModal";
import { PlayerUIDContext } from "../providers/PlayerUidProvider";
import ConfirmDeleteModal from "../components/modals/ConfirmDeleteModal";
import UserInfo from "../models/UserInfo";
import InstructionsScreenModal from "../components/modals/InstructionsScreenModal";
import { MessageProviderContext } from "../providers/MessageProvider";
import { Helmet } from "react-helmet";
import SubmittedPicture from "../models/SubmittedPicture";
import LobbySecondaryButton from "../components/LobbySecondaryButton";
import MediumBoxPicture from "../components/boxPictures/MediumBoxPicture";
import BoxFileUpload from "../components/boxPictures/BoxFileUpload";
import SubmittingPicturesTimerBar from "../components/timerBars/SubmittingPicturesTimerBar";
import resizeFile from "../utils/clientResizeFile";

type Props = {
  gameState: GameState;
  socket?: Socket;
};

type ViewingPhoto = {
  url: string;
  collectedBy?: string;
  collectedByPictureUrl?: string;
  collectedByLabel?: string;
};

function SubmittingPictures({ gameState, socket }: Props) {
  const controller = new AbortController();
  let [showInstructions, setShowInstructions] = useState(true);
  let [uploading, setUploading] = useState(false);
  let [percentUploaded, setPercentUploaded] = useState(0);
  let [viewingPhoto, setViewingPhoto] = useState<ViewingPhoto | undefined>(
    undefined
  );
  let [aboutToDeletePictureId, setAboutToDeletePictureId] = useState<
    string | undefined
  >("");
  let [userName, setUserName] = useState("");
  let [userPicture, setUserPicture] = useState("");
  let [numPicsSubmitted, setNumPicsSubmitted] = useState(0);
  let [viewCompleted, setViewCompleted] = useState(false);
  let { addMessage, clearMessages } = useContext(MessageProviderContext);

  let uidContext = useContext(PlayerUIDContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showInstructions]);

  useEffect(() => {
    let user = gameState.connectedUsers.find((u) => u.id === uidContext) as
      | UserInfo
      | undefined;
    if (user) {
      setUserName(user.name);
      setUserPicture(user.pictureUrl);
    }
  }, [gameState.connectedUsers, uidContext]);

  useEffect(() => {
    let numPicsSubmitted = gameState.mySubmittedPictures.filter(
      (p) => p.url
    ).length;
    if (numPicsSubmitted === 0) {
      setViewCompleted(false);
    }
    setNumPicsSubmitted(numPicsSubmitted);
  }, [gameState.mySubmittedPictures]);

  let shuffle = () => {
    socket?.emit("shuffle");
  };

  let onUserSelectPicture = async (e: any, id: string) => {
    if (!uploading) {
      let selectedFile = e.target.files?.[0] as File | undefined;

      if (selectedFile) {
        setUploading(true);
        clearMessages();

        // we'll resize the image first on the client
        let resized = await resizeFile(selectedFile);

        let formData = new FormData();
        formData.append("file", resized);
        formData.append("userId", uidContext);
        formData.append("gameCode", gameState.gameCode);
        formData.append("pictureId", id);

        axios
          .post("/api/uploadSubmittedPicture", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              if (progressEvent.total !== undefined)
                setPercentUploaded(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                );
            },
            signal: controller.signal,
          })
          .catch((err) => {
            console.error(err);
            addMessage(err.message);
          })
          .finally(() => {
            setUploading(false);
          });
      }
    }
  };

  let userCancelUpload = () => {
    if (uploading) {
      controller.abort();
      setUploading(false);
      setPercentUploaded(0);
    }
  };

  let instructionsText = `Find the random photos and take selfies with them! Fastest team gets the most points!`;

  let topText = () => {
    if (
      gameState.mySubmittedPictures.filter((p) => p.url !== "").length ===
      gameState.numCollectingPictures
    ) {
      return "Great job you found all of the hidden photos! Hang tight until the round ends";
    }
    return "Try to find and take selfies with all of the pics on the left! Tap a photo to see the full size";
  };

  let SubmitPictureRow = ({ pic }: { pic: SubmittedPicture }) => {
    let userPicArea = () => {
      if (pic.url) {
        return (
          <MediumBoxPicture
            src={pic.thumbnailUrl || pic.url}
            onClick={() =>
              setViewingPhoto({
                url: pic.url,
                collectedBy: `${userName} (You)`,
                collectedByPictureUrl: userPicture,
                collectedByLabel: "Found by:",
              })
            }
            topRight={pic.url ? <XMarkIcon /> : null}
            topRightOnClick={() => setAboutToDeletePictureId(pic.id)}
            topRightClassName="bg-primary-500"
          />
        );
      }

      return <BoxFileUpload onChange={(e) => onUserSelectPicture(e, pic.id)} />;
    };

    return (
      <div
        className="w-full px-5 md:px-10 mt-5 flex justify-evenly items-center space-x-5"
        key={pic.id}
      >
        <MediumBoxPicture
          src={pic.findingPictureThumbnailUrl || pic.findingPictureUrl}
          onClick={() =>
            setViewingPhoto({
              url: pic.findingPictureUrl,
              collectedBy: pic.collectedBy,
              collectedByPictureUrl: pic.collectedByPictureUrl,
              collectedByLabel: "Submitted by:",
            })
          }
          topRight={pic.url ? <CheckIcon /> : null}
          topRightOnClick={() => setAboutToDeletePictureId(pic.id)}
          topRightClassName="bg-tertiary-300"
        />
        {userPicArea()}
      </div>
    );
  };

  if (showInstructions) {
    return (
      <InstructionsScreenModal
        open={showInstructions}
        onClose={() => setShowInstructions(false)}
        title="Round Two!"
        showSoundsGood={true}
        subtitle={instructionsText}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Round Two - Pic&amp;Split</title>
        </Helmet>
      </InstructionsScreenModal>
    );
  }

  return (
    <GameBorder
      foreground="map-foreground"
      background="map-background"
      logo={false}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Round Two - Pic&amp;Split</title>
      </Helmet>
      <FileUploadModal
        open={uploading}
        percent={percentUploaded}
        onCancel={userCancelUpload}
      />
      <ViewPhotoModal
        open={!!viewingPhoto}
        onClose={() => setViewingPhoto(undefined)}
        imgSrc={viewingPhoto?.url || ""}
        collectedBy={viewingPhoto?.collectedBy}
        collectedByPictureUrl={viewingPhoto?.collectedByPictureUrl}
        collectedByLabel={viewingPhoto?.collectedByLabel}
      />
      <ConfirmDeleteModal
        open={!!aboutToDeletePictureId}
        onClose={() => setAboutToDeletePictureId(undefined)}
        onConfirm={() => {
          socket?.emit("delete_picture", aboutToDeletePictureId);
          setAboutToDeletePictureId(undefined);
        }}
      />
      <SubmittingPicturesTimerBar
        gameState={gameState}
        socket={socket}
        onViewPhotos={() => setViewCompleted((vc) => !vc)}
      />
      <img
        src="/picAndSplitLogoVerticalBlack.png"
        alt="Pic&amp;Split"
        className="mt-5 max-h-28 md:max-h-40 mx-auto"
      />

      <h3 className="text-5xl md:text-6xl pt-5 font-cursive text-center text-gray-900">
        Round Two
      </h3>

      <div className="text-2xl sm:text-3xl w-full text-center px-3">
        {topText()}
      </div>

      <div className="flex flex-col items-center text-center py-4">
        {viewCompleted ? (
          <LobbySecondaryButton
            className="px-5"
            onClick={() => setViewCompleted(false)}
          >
            Back to Finding
          </LobbySecondaryButton>
        ) : (
          numPicsSubmitted > 0 && (
            <LobbySecondaryButton
              className="px-5"
              onClick={() => setViewCompleted(true)}
            >
              {numPicsSubmitted} {numPicsSubmitted === 1 ? "Pic" : "Pics"}{" "}
              Found!
            </LobbySecondaryButton>
          )
        )}
      </div>

      {numPicsSubmitted === gameState.mySubmittedPictures.length && (
        <div className="text-4xl sm:text-5xl w-full text-center px-3 pt-8 pb-3">
          {" "}
          You did it!
        </div>
      )}

      <div className="flex flex-col justify-center items-center space-y-5">
        {viewCompleted
          ? gameState.mySubmittedPictures
              .filter((p) => p.url)
              .map((p) => <SubmitPictureRow key={p.id} pic={p} />)
          : gameState.mySubmittedPictures
              .filter((p) => !p.url)
              .slice(0, 3)
              .map((p) => <SubmitPictureRow key={p.id} pic={p} />)}
      </div>
      {!viewCompleted &&
        gameState.mySubmittedPictures.length - numPicsSubmitted > 3 && (
          <div className="flex flex-col items-center text-center py-4">
            <LobbySecondaryButton
              onClick={() => shuffle()}
              className="px-10 group"
            >
              <SparklesIcon className="w-7 h-7 inline mr-1 text-primary-500/90 group-hover:text-white hover:text-white" />
              Shuffle
            </LobbySecondaryButton>
          </div>
        )}
    </GameBorder>
  );
}

export default SubmittingPictures;
