export function log(logObject: any) {
  if (process.env.NODE_ENV !== "production") {
    console.log(logObject);
  } else {
    // TODO: set up with New Relic or similar
    console.log(logObject);
  }
}

export function noteError(errorObject: any) {
  if (process.env.NODE_ENV !== "production") {
    console.error(errorObject);
  } else {
    // TODO: set up with New Relic or similar
    console.error(errorObject);
  }
}
