import "../assets/css/loadingSpinner.css";

function LoadingSpinner() {
  return (
    <div className="profile-main-loader mt-10">
      <div className="loader">
        <svg className="circular-loader" viewBox="25 25 50 50" >
          <circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#FE7AB9" strokeWidth="4" />
        </svg>
      </div>
    </div>
  );
}

export default LoadingSpinner;
