import React from 'react'

type Props = {
  children: React.ReactNode,
  href?: string,
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
  className?: string,
  disabled?: boolean,
}

function LobbySecondaryButton({ children, href, onClick, className, disabled }: Props) {
  let klasses = `p-2 md:p-4 mt-7 bg-white text-2xl md:text-3xl text-primary-500 shadow-lg text-center border-2 border-primary-300 rounded-lg transition-all ease-in-out duration-250
  ${disabled ? "opacity-50" : "hover:bg-tertiary-300 hover:shadow-tertiary-700 hover:border-none hover:text-white hover:scale-105"} ${className || ""}`;

  if (href && !disabled)
    return <a href={href} className={klasses}>{children}</a>
  if (onClick)
    return <button onClick={onClick} className={klasses} disabled={disabled}>{children}</button>;
  return <div className={klasses}>{children}</div>;
}

export default LobbySecondaryButton;
