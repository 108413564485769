// picture for a user to find

export const AwardStatus = {
  Gold: "gold",
  Silver: "silver",
  Bronze: "bronze",
  Trash: "trash",
}

type SubmittedPicture = {
  id: string;
  url: string;
  thumbnailUrl?: string;

  findingPictureUrl: string;
  findingPictureThumbnailUrl?: string;
  collectedBy: string;
  collectedByPictureUrl: string; // thumbnail size

  awardStatus?: string;
}

export default SubmittedPicture;
