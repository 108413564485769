import React from 'react'

type Props = {
  children: React.ReactNode,
  href?: string,
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
  className?: string,
  disabled?: boolean,
  hrefNewTab?: boolean,
}

function HomeScreenButton({ children, href, onClick, className, disabled, hrefNewTab = false }: Props) {
  let klasses = `p-2 md:p-4 mt-7 bg-primary-300 text-white text-center text-2xl md:text-3xl w-9/12 shadow-primary-700 shadow-lg rounded-lg transition-all ease-in-out duration-250 
  ${disabled ? "opacity-50" : "hover:bg-secondary-300 hover:shadow-secondary-700 hover:text-primary-500 hover:scale-105 cursor-pointer"} ${className || ""}`;

  if (href && !disabled)
    if (hrefNewTab) {
      return <a href={href} target="_blank" rel="noopener noreferrer" className={klasses}>{children}</a>
    } else {
      return <a href={href} className={klasses}>{children}</a>
    }
  if (onClick)
    return <button onClick={onClick} className={klasses} disabled={disabled}>{children}</button>;
  return <div className={klasses}>{children}</div>;
}

export default HomeScreenButton;
