
function PSLoadingBar({ percent }: { percent: number }) {
  percent = Math.round(Math.min(Math.max(percent, 0), 100));

  return (
    <div className="mt-5 rounded-lg overflow-hidden w-full bg-secondary-300">
      <div className="flex align-middle w-full h-full bg-primary-300 rounded-lg text-left text-white transition-all duration-100 ease-linear" style={{ width: `${percent}%` }}>
        <span className="px-2 py-1 text-3xl h-full align-middle">{percent}%</span>
      </div>
    </div>
  );
}

export default PSLoadingBar;
