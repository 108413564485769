import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import HomeScreenButton from "../HomeScreenButton";

type Props = {
  open: boolean;
  onClose: () => void;
  qrcodeLink: string;
  gameCode: string;
};

function GameQRCodeModal({ open, onClose, qrcodeLink, gameCode }: Props) {
  let [copyLinkText, setCopyLinkText] = useState("Copy Link");

  if (!qrcodeLink) {
    return null;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => onClose()}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="relative inline-block align-bottom md:pt-5 text-left transform transition-all"
              style={{ minWidth: "250px" }}
            >
              <div className="flex flex-row justify-end">
                <XMarkIcon
                  className="text-white w-8 h-8 md:w-12 md:h-12 cursor-pointer"
                  onClick={onClose}
                />
              </div>
              <div className="w-full text-center items-center">
                <h1 className="text-white text-4xl md:text-6xl font-cursive">
                  Invite Others!
                </h1>
                <h2 className="text-primary-300 text-3xl">
                  Or go to{" "}
                  <span className="underline">
                    {window.location.href
                      .replace("https://", "")
                      .replace("www.", "")
                      .replace("/createGame", "")}
                  </span>{" "}
                  and enter the join code: {gameCode}
                </h2>
              </div>
              <img
                className="aspect-square mx-auto rounded-lg border-2 border-primary-300 mt-5"
                src={qrcodeLink}
                alt=""
                style={{ maxHeight: "50vh", maxWidth: "70vw" }}
              />
              <div className="flex flex-col items-center text-center pt-5">
                <HomeScreenButton
                  onClick={() =>
                    navigator.clipboard
                      .writeText(
                        `${window.location.href.replace(
                          "/createGame",
                          ""
                        )}/game/${gameCode}`
                      )
                      .then(() => setCopyLinkText("Copied!"))
                  }
                >
                  {copyLinkText}
                </HomeScreenButton>
                <HomeScreenButton onClick={() => onClose()}>
                  Done
                </HomeScreenButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default GameQRCodeModal;
