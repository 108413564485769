import { PhotoIcon } from "@heroicons/react/24/outline";
import { useId } from "react";

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

function BoxFileUpload({ onChange, disabled }: Props) {
  const id = useId();

  return (
    <div className="w-full mx-auto flex justify-center my-3">
      <label
        htmlFor={`file-upload-${id}`}
        className="cursor-pointer bg-white text-primary-300 h-28 w-28 md:h-36 md:w-36 text-3xl shadow-lg rounded-lg text-center border-2 border-primary-300
                flex flex-col items-center justify-center"
      >
        <PhotoIcon className="mx-auto h-16 w-16 md:h-24 md:w-24 text-primary-300" />
        <input
          id={`file-upload-${id}`}
          disabled={disabled}
          name={`file-upload-${id}`}
          type="file"
          accept="image/*"
          onChange={onChange}
          className="sr-only"
        />
      </label>
    </div>
  );
}

export default BoxFileUpload;
