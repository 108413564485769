import HomeScreenButton from "../HomeScreenButton";

type Props = {
  title: string,
  subtitle?: string,
  open: boolean,
  onClose: () => void,
  children?: React.ReactNode,
  showSoundsGood?: boolean
}

function InstructionsScreenModal({ title, subtitle, open, onClose, children, showSoundsGood }: Props) {
  if (!open) {
    return null;
  }

  return (
    <div className="w-full z-50 absolute top-0 left-0 hamburger-foreground" style={{ minWidth: "100vw", minHeight: "100vh" }}>
      <div className="flex flex-col justify-center items-center text-center h-full max-w-xl mx-auto my-10">
        <img src="/picAndSplitLogoVerticalBlack.png" alt="Pic&amp;Split" className="mt-5 max-h-32 md:max-h-40 mx-auto" />
        <h1 className="text-6xl md:text-7xl text-bold text-gray-900 font-cursive mt-5">{title}</h1>
        {subtitle && <h2 className="px-3 text-2xl md:text-3xl text-medium text-gray-900 mt-10">{subtitle}</h2>}
        {children}
        {showSoundsGood && <HomeScreenButton onClick={onClose}>Sounds Good!</HomeScreenButton>}
      </div>
    </div>
  )
}

export default InstructionsScreenModal;
