import PlayerUidProvider from './providers/PlayerUidProvider';
import MessageProvider from './providers/MessageProvider';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Game from './pages/Game';
import CreateGame from './pages/CreateGame';
import HomePage from './pages/HomePage';
import JoinGame from './pages/JoinGame';
import HighScores from './pages/HighScores';

function App() {
  return (
    <PlayerUidProvider>
      <MessageProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/highScores" element={<HighScores />} />
            <Route path="/highScores/:gameCode" element={<HighScores />} />
            <Route path="/game" element={<Game />} />
            <Route path="/game/:gameCode" element={<Game />} />
            <Route path="/createGame" element={<CreateGame />} />
            <Route path="/joinGame" element={<JoinGame />} />
            <Route path="/joinGame/:gameCode" element={<JoinGame />} />
            <Route path="/" element={<HomePage />} />
            <Route path="*" element={<HomePage />} />
          </Routes>
        </BrowserRouter>
      </MessageProvider >
    </PlayerUidProvider >
  );
}

export default App;
