import BaseBoxPicture, { BaseBoxPictureProps } from "./BaseBoxPicture";

function SmallBoxPicture(args: BaseBoxPictureProps) {
  const floaterSize = "h-9 w-9 sm:h-9 sm:w-9 md:h-10 md:w-10";
  return <BaseBoxPicture
    {...args}
    className={`h-24 w-24 md:h-32 md:w-32 ${args.className || ''}`}
    topLeftClassName={`${floaterSize} ${args.topLeftClassName || ''}`}
    topRightClassName={`${floaterSize} ${args.topRightClassName || ''}`}
    bottomLeftClassName={`${floaterSize} ${args.bottomLeftClassName || ''}`}
    bottomRightClassName={`${floaterSize} ${args.bottomRightClassName || ''}`}
  />;
}

export default SmallBoxPicture;
