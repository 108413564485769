import { useContext, useLayoutEffect, useState } from 'react';
import GameState from '../../models/GameState';
import { PlayerUIDContext } from '../../providers/PlayerUidProvider';

export default function PercentageBar({ gameState, inHotSauce }: { gameState: GameState; inHotSauce: boolean }) {
  let [myPercentage, setMyPercentage] = useState(0);
  let [otherTopPercentage, setOtherTopPercentage] = useState(0);
  let uidContext = useContext(PlayerUIDContext);

  useLayoutEffect(() => {
    let user = gameState.connectedUsers.find(u => u.id === uidContext);

    if (user) {
      setMyPercentage(user.racePercentage);

      let bestOtherPerson = 0;
      gameState.connectedUsers.forEach(u => {
        if (u.id !== uidContext && u.racePercentage > bestOtherPerson) {
          bestOtherPerson = u.racePercentage;
        }
      });

      setOtherTopPercentage(bestOtherPerson);
    }
  }, [gameState.connectedUsers, gameState.gameStatus, uidContext]);

  return (
    <div className='w-100'>
      <div
        className='absolute h-2 rounded-md bg-secondary-300 transition-all duration-1000'
        style={{ width: `${Math.min(myPercentage, 96)}%` }}
      ></div>
      <div
        className={`h-2 rounded-md ${inHotSauce ? 'bg-tertiary-300' : 'bg-primary-300'} transition-all duration-1000`}
        style={{ width: `${Math.min(otherTopPercentage, 96)}%` }}
      ></div>
      {gameState.connectedUsers.map(u => {
        return (
          <div
            key={u.id}
            className='absolute transition-all duration-1000 overflow-visible'
            style={{ width: `${Math.min(u.racePercentage, 96)}%` }}
          >
            <div
              className={`absolute ${
                u.id === uidContext ? 'z-20' : 'z-10'
              } w-10 top-0 right-0 transform -translate-y-1/2 ${
                u.racePercentage === 0 ? 'translate-x-full' : 'translate-x-1/2'
              }`}
            >
              <img
                src={u.pictureUrl}
                alt=''
                className={`${u.id === uidContext ? 'w-8 h-8' : 'w-6 h-6'} align-middle text-white rounded-full border`}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
