import { useEffect, useState } from 'react';
import GameState, { GameStatus } from '../models/GameState';
import formatTimer from '../utils/formatMills';

export default function BottomTimer({ gameState }: { gameState: GameState }) {
  let [timer, setTimer] = useState('0:00');
  let [showTimer, setShowTimer] = useState(false);

  let { LobbyWaiting, ReturnToGroup } = GameStatus;

  useEffect(() => {
    let updateShowTimer = () => {
      if (gameState.gameStatus === LobbyWaiting || gameState.gameStatus === ReturnToGroup) {
        setShowTimer(false);
      } else {
        setShowTimer(true);
      }
    };

    updateShowTimer();
    setTimer(formatTimer(gameState.roundEndTimestamp - Date.now()));

    let id = setInterval(() => {
      updateShowTimer();
      setTimer(formatTimer(gameState.roundEndTimestamp - Date.now()));
    }, 1000);

    return () => clearInterval(id);
  }, [gameState.roundEndTimestamp, LobbyWaiting, ReturnToGroup, gameState.amAdmin, gameState.gameStatus]);

  return (
    <div
      className={`fixed ${
        showTimer ? '' : 'invisible'
      } bottom-0 left-0 right-0 mx-auto w-fit text-center rounded-t-xl bg-primary-300 p-2 transition-all duration-1000`}
    >
      <div className='text-white font-mono text-4xl sm:text-5xl md:text-6xl select-none'>{timer}</div>
    </div>
  );
}
