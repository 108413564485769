import { useEffect, useContext } from 'react';
import { Socket } from 'socket.io-client';
import GameBorder from '../components/GameBorder';
import LobbySecondaryButton from '../components/LobbySecondaryButton';
import LobbyTimerBar from '../components/timerBars/LobbyTimerBar';
import GameState, { GameStatus } from '../models/GameState';
import { PlayerUIDContext } from '../providers/PlayerUidProvider';
import { Helmet } from 'react-helmet';

type Props = {
  gameState: GameState;
  setEditingInfo: (editingInfo: boolean) => void;
  socket?: Socket;
};

// just have Game pass connected users to the lobby?
function Lobby({ gameState, setEditingInfo, socket }: Props) {
  let uidContext = useContext(PlayerUIDContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <GameBorder
      foreground='anchor-foreground'
      background='anchor-background'
      logo={false}
    >
      <Helmet>
        <meta charSet='utf-8' />
        <title>Lobby - Pic&amp;Split</title>
      </Helmet>
      <LobbyTimerBar
        gameState={gameState}
        socket={socket}
      />
      <img
        src='/picAndSplitLogoVerticalBlack.png'
        alt='Pic&amp;Split'
        className='mt-5 max-h-32 md:max-h-40 mx-auto'
      />

      {gameState.amAdmin && socket && (
        <>
          <div className='flex flex-col items-center'>
            {gameState.gameStatus === GameStatus.LobbyWaiting ? (
              <LobbySecondaryButton
                disabled={gameState.connectedUsers.length <= 1}
                onClick={() => socket.emit('start_game')}
              >
                {gameState.connectedUsers.length <= 1 ? 'Need 2+ players' : 'Start Game'}
              </LobbySecondaryButton>
            ) : (
              <LobbySecondaryButton onClick={() => socket.emit('cancel_game_start')}>Cancel Start</LobbySecondaryButton>
            )}
          </div>
        </>
      )}

      {!gameState.amAdmin &&
        gameState.gameStatus === GameStatus.LobbyWaiting &&
        gameState.connectedUsers.length > 1 && (
          <div className='flex flex-col items-center mx-5 mt-3 text-primary-300 text-2xl'>
            Ask the admin to start the game!
          </div>
        )}

      <ul className='grid grid-cols-2 p-3 sm:p-5'>
        {gameState.connectedUsers.map(person => (
          <li
            className='col-span-1 flex flex-col text-center rounded-lg '
            key={person.id}
          >
            <div className={`flex-1 flex flex-col p-8 ${person.id === uidContext ? 'cursor-pointer' : ''}`}>
              <span className='inline-block relative'>
                <img
                  className={`w-24 h-24 md:w-32 md:h-32 flex-shrink-0 mx-auto rounded-full border-2 ${
                    person.ready ? 'border-tertiary-300' : 'border-primary-300 opacity-60'
                  }`}
                  src={person.pictureUrl}
                  alt=''
                  onClick={() => person.id === uidContext && setEditingInfo(true)}
                />
              </span>
              <h3 className={`text-xl md:text-3xl font-medium ${person.ready ? 'text-gray-900' : 'text-gray-400'}`}>{`${
                person.name
              }${person.id === uidContext ? ' (You)' : ''}`}</h3>
            </div>
          </li>
        ))}
      </ul>
      <div className='mx-5 mt-3 text-primary-300 text-xl'>
        Note: Pic&amp;Split is still in beta, please be patient with any bugs or issues. Thanks for playing!! You can
        send feedback to corban@corbanha.com
      </div>
    </GameBorder>
  );
}

export default Lobby;
