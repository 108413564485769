import { useState, useContext, useEffect } from "react";
import GameBorder from "../components/GameBorder";
import { Socket } from "socket.io-client";
import GameState from "../models/GameState";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import FileUploadModal from "../components/modals/FileUploadModal";
import { PlayerUIDContext } from "../providers/PlayerUidProvider";
import { MessageProviderContext } from "../providers/MessageProvider";
import ViewPhotoModal from "../components/modals/ViewPhotoModal";
import ConfirmDeleteModal from "../components/modals/ConfirmDeleteModal";
import GameTimerBar from "../components/timerBars/CollectingPicturesTimerBar";
import InstructionsScreenModal from "../components/modals/InstructionsScreenModal";
import { Helmet } from "react-helmet";
import { noteError } from "../utils/logger";
import MediumBoxPicture from "../components/boxPictures/MediumBoxPicture";
import BoxFileUpload from "../components/boxPictures/BoxFileUpload";
import resizeFile from "../utils/clientResizeFile";

type Props = {
  gameState: GameState;
  socket?: Socket;
};

function CollectingPictures({ gameState, socket }: Props) {
  const controller = new AbortController();
  let [showInstructions, setShowInstructions] = useState(true);
  let [inHotSauce, setInHotSauce] = useState(false);
  let [viewingPhoto, setViewingPhoto] = useState<string | undefined>(undefined);
  let [uploading, setUploading] = useState(false);
  let [aboutToDeletePictureId, setAboutToDeletePictureId] = useState<
    string | undefined
  >("");
  let [percentUploaded, setPercentUploaded] = useState(0);
  let [outOfTime, setOutOfTime] = useState(false);

  let [userName, setUserName] = useState("");
  let [userPicture, setUserPicture] = useState("");
  let uidContext = useContext(PlayerUIDContext);
  let { addMessage, clearMessages } = useContext(MessageProviderContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showInstructions]);

  useEffect(() => {
    if (gameState.myPictures.length < gameState.numCollectingPictures) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [gameState.myPictures, gameState.numCollectingPictures]);

  useEffect(() => {
    let user = gameState.connectedUsers.find((u) => u.id === uidContext);
    if (user) {
      setUserName(user.name);
      setUserPicture(user.pictureUrl);
    }
  }, [gameState.connectedUsers, uidContext]);

  useEffect(() => {
    const checkInHotSauce = () => {
      return (
        Date.now() > gameState.roundEndTimestamp &&
        gameState.myPictures.length < gameState.numCollectingPictures
      );
    };
    setOutOfTime(Date.now() > gameState.roundEndTimestamp);
    setInHotSauce(checkInHotSauce);

    let buffer = 500;
    let futureCheck = gameState.roundEndTimestamp - Date.now() + buffer;

    if (futureCheck > 0) {
      let id = setTimeout(() => {
        setOutOfTime(Date.now() > gameState.roundEndTimestamp);
        setInHotSauce(checkInHotSauce);
      }, futureCheck);
      return () => clearTimeout(id);
    }
  }, [
    gameState.roundEndTimestamp,
    gameState.myPictures,
    gameState.numCollectingPictures,
  ]);

  let onUserSelectPicture = async (e: any) => {
    if (!uploading) {
      let selectedFile = e.target.files?.[0] as File | undefined;

      if (selectedFile) {
        setUploading(true);
        clearMessages();

        let resized = await resizeFile(selectedFile);

        let formData = new FormData();
        formData.append("file", resized);
        formData.append("userId", uidContext);
        formData.append("gameCode", gameState.gameCode);

        axios
          .post("/api/uploadCollectedPicture", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              if (progressEvent.total)
                setPercentUploaded(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                );
            },
            signal: controller.signal,
          })
          .catch((err) => {
            noteError(err);
            addMessage(err.message);
          })
          .finally(() => {
            setUploading(false);
          });
      }
    }
  };

  let userCancelUpload = () => {
    if (uploading) {
      controller.abort();
      setUploading(false);
      setPercentUploaded(0);
    }
  };

  let instructionsText = `Collect ${gameState.numCollectingPictures} 
  pictures for the photo scavenger hunt! Others are going to try and find your pictures in the next round so be clever!`;

  let topText = () => {
    if (inHotSauce)
      return "You've run out of time! If you hurry you might still be able to get one more pic or two in before the next round starts!";
    if (gameState.myPictures.length === gameState.numCollectingPictures) {
      if (outOfTime) {
        return "Letting some of the other teams finish collecting their pictures...";
      }
      return "Great job you got all of your photos! Hang tight until the round ends...";
    }
    return `Collect ${gameState.numCollectingPictures} pics that will be used in the next round for others to find!`;
  };

  if (showInstructions) {
    return (
      <InstructionsScreenModal
        open={showInstructions}
        onClose={() => setShowInstructions(false)}
        title="Round One!"
        showSoundsGood={true}
        subtitle={instructionsText}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Round One - Pic&amp;Split</title>
        </Helmet>
      </InstructionsScreenModal>
    );
  }

  return (
    <GameBorder
      foreground={`${inHotSauce ? "skulls-foreground" : "anchor-foreground"}`}
      background={`${inHotSauce ? "skulls-background" : "anchor-background"}`}
      logo={false}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Round One - Pic&amp;Split</title>
      </Helmet>
      <FileUploadModal
        open={uploading}
        percent={percentUploaded}
        onCancel={userCancelUpload}
      />
      <ViewPhotoModal
        open={!!viewingPhoto}
        onClose={() => setViewingPhoto(undefined)}
        imgSrc={viewingPhoto || ""}
        collectedBy={userName}
        collectedByPictureUrl={userPicture}
        collectedByLabel={"Submitted by:"}
      />
      <ConfirmDeleteModal
        open={!!aboutToDeletePictureId}
        onClose={() => setAboutToDeletePictureId(undefined)}
        onConfirm={() => {
          socket?.emit("delete_picture", aboutToDeletePictureId);
          setAboutToDeletePictureId(undefined);
        }}
      />

      <GameTimerBar gameState={gameState} inHotSauce={inHotSauce} />
      <img
        src="/picAndSplitLogoVerticalBlack.png"
        alt="Pic&amp;Split"
        className="mt-5 max-h-28 md:max-h-40 mx-auto"
      />
      <h3 className="text-5xl md:text-6xl pt-5 font-cursive text-center text-gray-900">
        Round One
      </h3>

      <div
        className={`text-2xl sm:text-3xl w-full text-center px-3 ${
          inHotSauce ? "text-primary-500" : "text-gray-900"
        }`}
      >
        {topText()}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 px-2 mt-5">
        {gameState.myPictures.map((picture) => (
          <MediumBoxPicture
            key={picture.id}
            src={picture.thumbnailUrl || picture.url}
            onClick={() => setViewingPhoto(picture.url)}
            topRight={!outOfTime ? <XMarkIcon /> : null}
            topRightOnClick={() => setAboutToDeletePictureId(picture.id)}
            topRightClassName="bg-primary-500"
          />
        ))}
        {gameState.myPictures.length < gameState.numCollectingPictures && (
          <BoxFileUpload onChange={onUserSelectPicture} disabled={uploading} />
        )}
      </div>
    </GameBorder>
  );
}

export default CollectingPictures;
