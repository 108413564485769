import React from 'react';

type Props = {
  children: React.ReactNode,
  href?: string,
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
  className?: string,
  disabled?: boolean,
}

function NameAvatarButton({ children, href, onClick, className, disabled }: Props) {
  let klasses = `w-9/12 p-2 md:p-4 mt-7 bg-primary-300 text-white text-2xl md:text-3xl shadow-primary-700 shadow-lg rounded-lg transition-all ease-in-out duration-250
  ${disabled ? "opacity-50" : "hover:bg-tertiary-300 hover:text-white hover:shadow-tertiary-700 hover:scale-105 cursor-pointer"} ${className || ""}`;

  if (href && !disabled)
    return <a href={href} className={klasses}>{children}</a>
  if (onClick)
    return <button onClick={onClick} className={klasses} disabled={disabled}>{children}</button>;
  return <div className={klasses}>{children}</div>;
}

export default NameAvatarButton;
