import { useState, useEffect } from 'react';
import GameBorder from '../components/GameBorder';
import GameState from '../models/GameState';
import { Helmet } from "react-helmet";
import UserInfo from '../models/UserInfo';

type DisplayHighScore = UserInfo & {
  finalHighScore: number;
}

function HighScoresUpdate({ gameState }: { gameState: GameState }) {
  let [highScores, setHighScores] = useState<DisplayHighScore[]>(gameState.connectedUsers.map(u => ({ ...u, score: 0, finalHighScore: u.score } as DisplayHighScore)));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let id = setInterval(() => {
      setHighScores(highScores.map(hs => ({ ...hs, score: Math.min(hs.score + 4 + Math.floor(hs.finalHighScore * 0.1 * (hs.finalHighScore - hs.score) / hs.finalHighScore), hs.finalHighScore) } as DisplayHighScore)));
    }, 50);

    return () => clearInterval(id);
  }, [highScores]);

  if (!gameState.highScores) {
    return (
      <GameBorder foreground='anchor-foreground' background='anchor-background'>
        <h3 className="text-6xl pt-5 font-cursive text-center text-gray-900">Loading...</h3>
      </GameBorder>
    );
  }

  return (
    <GameBorder foreground='anchor-foreground' background='anchor-background'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>High Score Update - Pic&amp;Split</title>
      </Helmet>
      <h3 className="text-6xl pt-5 font-cursive text-center text-gray-900">Scores So Far!</h3>
      {highScores.map(highScore => (
        <div className="flex flex-row justify-between items-center mx-5 mt-5 px-2 sm:px-5" key={highScore.id}>
          <div className="flex flex-row items-center">
            <img className="w-16 h-16 sm:w-20 sm:h-20 rounded-full border-2 bg-white border-tertiary-300" src={highScore.pictureUrl} alt="avatar" />
            <h3 className=" text-3xl sm:text-4xl ml-3 text-center text-gray-900">{highScore.name}</h3>
          </div>
          <h3 className=" text-3xl sm:text-4xl text-center text-gray-900">{highScore.score}</h3>
        </div>
      ))}
      <h3 className="text-3xl sm:text-4xl pt-10 text-center text-gray-900">Get ready for the next round...</h3>
    </GameBorder>
  )
}

export default HighScoresUpdate;
