import { createContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const PlayerUIDContext = createContext<string>('');

function PlayerUIDProvider({ children }: { children: JSX.Element }) {
  const [playerUID, setPlayerUID] = useState<string>('');

  useEffect(() => {
    let uid = localStorage.getItem('picAndSplitUID') || "";

    if (uid) {
      setPlayerUID(uid);
    } else {
      uid = uuidv4();
      localStorage.setItem('picAndSplitUID', uid);
      setPlayerUID(uid);
    }
  }, []);

  return (
    <PlayerUIDContext.Provider value={playerUID}>
      {children}
    </PlayerUIDContext.Provider>
  );
}

export default PlayerUIDProvider;
