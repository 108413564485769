import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/24/outline";
import HomeScreenButton from "../HomeScreenButton";

type Props = {
  open: boolean;
  buttonTitle: string;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
};

function ConfirmModal({
  open,
  buttonTitle,
  message,
  onClose,
  onConfirm,
}: Props) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-40 inset-0 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 file-modal-background">
              <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-primary-300">
                <TrashIcon
                  className="h-14 w-14 text-white"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title
                  as="h3"
                  className="text-3xl sm:text-4xl font-medium text-gray-900"
                >
                  {message}
                </Dialog.Title>
                <Dialog.Description
                  as="div"
                  className="mt-2 text-2xl text-gray-600"
                >
                  <HomeScreenButton
                    onClick={onConfirm}
                    className="focus-visible:outline-hidden focus:outline-none my-5"
                  >
                    {buttonTitle}
                  </HomeScreenButton>
                  <button
                    onClick={onClose}
                    className={
                      "w-9/12 p-4 my-5 bg-tertiary-300 text-white text-2xl sm:text-3xl shadow-tertiary-700 focus-visible:outline-hidden focus:outline-none shadow-lg rounded-lg transition-all ease-in-out duration-250 hover:bg-secondary-300 hover:shadow-secondary-700 hover:text-primary-500 hover:scale-105 cursor-pointer"
                    }
                  >
                    Cancel
                  </button>
                </Dialog.Description>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ConfirmModal;
