import HomeScreenBorder from "../components/GameBorder";
import { useState, useEffect, useContext } from "react";
import NameAvatarButton from "../components/NameAvatarButton";
import FileUploadModal from "../components/modals/FileUploadModal";
import axios from "axios";
import GameState from "../models/GameState";
import { Socket } from "socket.io-client";
import { SparklesIcon, PhotoIcon } from "@heroicons/react/24/outline";
import { PlayerUIDContext } from "../providers/PlayerUidProvider";
import {
  MessageProviderContext,
  MessageType,
} from "../providers/MessageProvider";
import { Helmet } from "react-helmet";
import { noteError } from "../utils/logger";
import resizeFile from "../utils/clientResizeFile";

type Props = {
  gameState: GameState;
  socket: Socket | undefined;
  setEditingInfo: (editingInfo: boolean) => void;
};

function randomAvatar() {
  let avatarUrls = [
    "https://pic-and-split.nyc3.digitaloceanspaces.com/pic-and-split-prod/midjourney-avatars/small_silly_hamburger.png",
    "https://pic-and-split.nyc3.digitaloceanspaces.com/pic-and-split-prod/midjourney-avatars/small_ice_cream.png",
    "https://pic-and-split.nyc3.digitaloceanspaces.com/pic-and-split-prod/midjourney-avatars/small_donut.png",
    "https://pic-and-split.nyc3.digitaloceanspaces.com/pic-and-split-prod/midjourney-avatars/small_pizza.png",
    "https://pic-and-split.nyc3.digitaloceanspaces.com/pic-and-split-prod/midjourney-avatars/small_cookie.png",
    "https://pic-and-split.nyc3.digitaloceanspaces.com/pic-and-split-prod/midjourney-avatars/small_cupcake.png",
  ];

  let randomIndex = Math.floor(Math.random() * avatarUrls.length);
  return avatarUrls[randomIndex];
}

function NameAvatar({ gameState, socket, setEditingInfo }: Props) {
  let controller = new AbortController();
  let cancelTokenSource = axios.CancelToken.source();
  let [nickname, setNickname] = useState(
    localStorage.getItem("PicAndSplitNickname") || ""
  );
  let [pictureUrl, setPictureUrl] = useState(
    localStorage.getItem("PicAndSplitAvatarUrl") || ""
  );
  let [uploading, setUploading] = useState(false);
  let [percentUploaded, setPercentUploaded] = useState(0);
  let { addMessage, clearMessages } = useContext(MessageProviderContext);
  let uidContext = useContext(PlayerUIDContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let onClickRandomPicture = () => {
    if (!uploading) {
      let tempRandomAvatar = randomAvatar();
      while (tempRandomAvatar === pictureUrl) {
        tempRandomAvatar = randomAvatar();
      }
      setPictureUrl(tempRandomAvatar);
      clearMessages();
    }
  };

  let onClickUploadPicture = async (e: any) => {
    if (!uploading) {
      let selectedFile = e.target.files?.[0] as File | undefined;
      // controller = new AbortController();

      if (selectedFile) {
        setUploading(true);

        let resized = await resizeFile(selectedFile, 720);

        let formData = new FormData();
        formData.append("file", resized);
        formData.append("gameCode", gameState.gameCode);
        formData.append("userId", uidContext);

        axios
          .post("/api/uploadAvatar", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              if (progressEvent.total)
                setPercentUploaded(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                );
            },
            // signal: controller.signal,
            // cancelToken: cancelTokenSource.token,
          })
          .then((res) => {
            setPictureUrl(res.data.destination);
            clearMessages();
          })
          .catch((err) => {
            noteError(err);
            addMessage("Error uploading custom avatar");
          })
          .finally(() => {
            setUploading(false);
          });
      }
    }
  };

  let setLocalData = (nickname: string, avatarUrl: string) => {
    localStorage.setItem("picAndSplitDoneEditing", "true");
    localStorage.setItem("PicAndSplitNickname", nickname);
    localStorage.setItem("PicAndSplitAvatarUrl", avatarUrl);
  };

  let userCancelUpload = () => {
    controller.abort();
    cancelTokenSource.cancel("User cancelled upload");
    setUploading(false);
    setPercentUploaded(0);
    addMessage("User cancelled photo upload", MessageType.INFO);
  };

  let userReady = () => {
    if (!uploading && pictureUrl && nickname.trim().length > 0) {
      setLocalData(nickname.substring(0, 15), pictureUrl);
      socket?.emit("set_player_name", nickname.substring(0, 15));
      socket?.emit("set_player_picture", pictureUrl);
      setEditingInfo(false);
    } else {
      addMessage("Please enter a nickname and select a photo");
    }
  };

  let generateCenterPicture = () => {
    if (pictureUrl) {
      return (
        <label htmlFor="file-upload">
          <img
            className="inline-block h-32 w-32 md:h-44 md:w-44 rounded-full border-2 border-primary-300 cursor-pointer"
            src={pictureUrl}
            alt="Avatar"
          />
          <input
            id="file-upload"
            disabled={uploading}
            name="file-upload"
            type="file"
            accept="image/*"
            onChange={onClickUploadPicture}
            className="sr-only group"
          />
        </label>
      );
    } else {
      return (
        <div className="w-full mx-auto flex justify-center my-3 hover:scale-110 transition-all ease-in-out duration-200">
          <label
            htmlFor="file-upload-2"
            className="cursor-pointer bg-white text-primary-500 h-32 w-32 md:h-44 md:w-44 text-3xl shadow-lg rounded-full border-2 border-primary-300
                  flex flex-col items-center justify-center"
          >
            <PhotoIcon className="mx-auto h-20 w-20 md:h-24 md:w-24 text-primary-300" />
            <input
              id="file-upload-2"
              disabled={uploading}
              name="file-upload-2"
              type="file"
              accept="image/*"
              onChange={onClickUploadPicture}
              className="sr-only group"
            />
          </label>
        </div>
      );
    }
  };

  return (
    <HomeScreenBorder
      background="anchor-background"
      foreground="anchor-foreground"
      logo={true}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Choose Avatar - Pic&amp;Split</title>
      </Helmet>
      <FileUploadModal
        open={uploading}
        percent={percentUploaded}
        onCancel={userCancelUpload}
      ></FileUploadModal>

      <div className="w-full flex flex-col items-center mb-5">
        <div className="sm:px-2 pt-10">
          <label htmlFor="nickname" className="text-3xl text-gray-800">
            Nickname:
          </label>
          <input
            type="text"
            name="nickname"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            className="flex-1 block w-full min-w-0 rounded-md text-2xl sm:text-3xl p-1 sm:p-2 align-middle bg-white border-primary-300 border-2 pt-3"
          />
        </div>
        <div className="sm:px-2 pt-8">{generateCenterPicture()}</div>
        <div className="sm:px-2 pt-5 w-full flex justify-center">
          <button
            className="cursor-pointer p-2 md:p-4 text-2xl md:text-3xl w-9/12 mt-7 bg-white text-primary-500 shadow-lg rounded-lg transition-all ease-in-out duration-250 text-center border-2 border-primary-300
        hover:bg-tertiary-300 hover:shadow-tertiary-700 hover:border-none hover:text-white hover:scale-105 group"
            onClick={onClickRandomPicture}
          >
            <span>
              <SparklesIcon className="w-7 h-7 inline mr-1 text-primary-500/90 group-hover:text-white hover:text-white" />
              Randomize Avatar
            </span>
          </button>
        </div>
        <NameAvatarButton
          disabled={uploading || nickname.trim().length === 0 || !pictureUrl}
          onClick={userReady}
        >
          {pictureUrl
            ? nickname.trim().length === 0
              ? "Add a nickname"
              : "I'm Ready!"
            : "Select an avatar!"}
        </NameAvatarButton>
      </div>
    </HomeScreenBorder>
  );
}

export default NameAvatar;
