import { MinusIcon, PlusIcon, BackwardIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Socket } from "socket.io-client";
import GameState, { GameStatus } from "../models/GameState";
import ConfirmModal from "./modals/ConfirmModal";

let {
  LobbyWaiting,
  LobbyCountdown,
  PostRoundOneHighScores,
  PostRoundTwoHighScores,
  ReturnToGroup,
} = GameStatus;

export default function AdminControls({
  gameState,
  socket,
}: {
  gameState: GameState;
  socket: Socket | undefined;
}) {
  let [confirmPrevious, setConfirmPrevious] = useState(false);

  if (
    !gameState ||
    !gameState.amAdmin ||
    [
      LobbyWaiting,
      LobbyCountdown,
      PostRoundOneHighScores,
      PostRoundTwoHighScores,
      ReturnToGroup,
    ].includes(gameState.gameStatus)
  ) {
    return null;
  }

  return (
    <>
      <ConfirmModal
        open={confirmPrevious}
        buttonTitle="Go back"
        message={
          "Are you sure you'd like to go back to the previous round? Data may be lost by going back!"
        }
        onConfirm={() => {
          setConfirmPrevious(false);
          socket?.emit("previous_round");
        }}
        onClose={() => setConfirmPrevious(false)}
      />

      <div
        onClick={() => setConfirmPrevious(true)}
        className="fixed p-3 left-0 top-24 z-10 rounded-r-full text-white bg-primary-300 cursor-pointer"
      >
        <BackwardIcon className="h-6 w-6" />
      </div>
      <div
        onClick={() => {
          socket?.emit("time_plus");
        }}
        className="fixed p-3 left-0 top-40 z-10 rounded-r-full text-white bg-primary-300 cursor-pointer"
      >
        <PlusIcon className="h-6 w-6" />
      </div>
      <div
        onClick={() => {
          socket?.emit("time_minus");
        }}
        className="fixed p-3 left-0 top-56 z-10 rounded-r-full text-white bg-primary-300 cursor-pointer"
      >
        <MinusIcon className="h-6 w-6" />
      </div>
    </>
  );
}
