import { PhotoIcon } from "@heroicons/react/24/outline";
import { Socket } from "socket.io-client";
import GameState from "../../models/GameState";
import { useState, useEffect, useContext } from "react";
import ConfirmModal from "../modals/ConfirmModal";
import { PlayerUIDContext } from "../../providers/PlayerUidProvider";
import PercentageBar from "./PercentageBar";

type Props = {
  gameState: GameState;
  socket?: Socket;
  onViewPhotos: () => void;
};

function SubmittingPicturesTimerBar({
  gameState,
  socket,
  onViewPhotos,
}: Props) {
  let [confirmPrevious, setConfirmPrevious] = useState(false);
  let [userScore, setUserScore] = useState(0);
  let uidContext = useContext(PlayerUIDContext);

  useEffect(() => {
    let newScore =
      gameState.connectedUsers.find((u) => u.id === uidContext)?.score || 0;
    let id: NodeJS.Timeout;
    if (userScore === 0) {
      setUserScore(newScore);
    } else {
      let checkFunc = () => {
        id = setTimeout(() => {
          setUserScore((userScore) => {
            if (newScore > userScore) {
              checkFunc();
              return Math.min(
                userScore +
                  4 +
                  Math.floor(
                    (newScore * 0.1 * (newScore - userScore)) / newScore
                  ),
                newScore
              );
            } else if (newScore < userScore) {
              checkFunc();
              return Math.max(
                userScore -
                  4 -
                  Math.floor(
                    (userScore * 0.1 * (userScore - newScore)) / userScore
                  ),
                newScore
              );
            }
            return userScore;
          });
        }, 50);
      };
      checkFunc();
    }

    return () => clearTimeout(id);
    // eslint-disable-next-line
  }, [gameState.connectedUsers, uidContext]);

  return (
    <div className="sticky top-0 z-10 w-full text-center shadow-lg shadow-tertiary-700/80 rounded-b-xl transition-all ease-linear duration-300 bg-tertiary-300">
      <ConfirmModal
        open={confirmPrevious}
        buttonTitle="Go back"
        message={
          "Are you sure you'd like to go back to the previous round? Data may be lost by going back!"
        }
        onConfirm={() => socket?.emit("previous_round")}
        onClose={() => setConfirmPrevious(false)}
      />
      <div className="text-white select-none flex flex-row justify-between items-center mx-5">
        <span
          onClick={() => onViewPhotos()}
          className="text-5xl md:text-6xl font-mono font-bold py-2 cursor-pointer"
        >
          {gameState.mySubmittedPictures.filter((sp) => sp.url).length}
          <PhotoIcon className="inline w-12 h-12 md:w-16 md:h-16 rotate-6" />
        </span>

        <div className="flex justify-between space-x-5 items-center">
          <span className="text-5xl md:text-6xl font-mono font-bold">
            {userScore}
          </span>
        </div>
      </div>
      <PercentageBar gameState={gameState} inHotSauce={false} />
    </div>
  );
}

export default SubmittingPicturesTimerBar;
