import { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Socket } from "socket.io-client"
import MediumBoxPicture from '../components/boxPictures/MediumBoxPicture';
import SmallBoxPicture from '../components/boxPictures/SmallBoxPicture';
import GameBorder from '../components/GameBorder';
import InstructionsScreenModal from '../components/modals/InstructionsScreenModal';
import ViewPhotoModal from '../components/modals/ViewPhotoModal';
import GameState from "../models/GameState"
import JudgingPicture from '../models/JudgingPicture';
import { AwardStatus } from '../models/SubmittedPicture';
import UserInfo from '../models/UserInfo';
import { PlayerUIDContext } from '../providers/PlayerUidProvider';
import JudgingPicturesTimerBar from '../components/timerBars/JudgingPicturesTimerBar';

type Props = {
  gameState: GameState,
  socket?: Socket
}

type ViewingPhoto = {
  url: string,
  collectedBy?: string,
  collectedByPictureUrl?: string,
  collectedByLabel?: string
}

const { Gold, Silver, Bronze, Trash } = AwardStatus;

function JudgingPictures({ gameState, socket }: Props) {
  let [showInstructions, setShowInstructions] = useState(true);
  let [viewingPhoto, setViewingPhoto] = useState<ViewingPhoto | undefined>(undefined);
  let [assigningRound, setAssigningRound] = useState<string | null>(Gold);
  let [userName, setUserName] = useState('');
  let [userPicture, setUserPicture] = useState('');

  let uidContext = useContext(PlayerUIDContext);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [showInstructions]);

  useEffect(() => {
    let user = gameState.connectedUsers.find(u => u.id === uidContext) as UserInfo | undefined;
    if (user) {
      setUserName(user.name);
      setUserPicture(user.pictureUrl);
    }
  }, [gameState.connectedUsers, uidContext]);

  let instructionsText = `It's time to see how people did with your photos! Assign gold, silver, and bronze trophies to the 3 best photos!`;

  let assignTrophy = (id: string) => {
    let pic: { id: string, awardStatus?: string } | undefined;

    gameState.submissionsToMyPictures.forEach(jp => {
      if (!pic) {
        jp.submittedPictures.forEach(sp => {
          if (sp.id === id) {
            pic = sp;
          }
        });
      }
    });

    if (pic && socket) {
      if (pic.awardStatus) {
        socket.emit('remove_trophy', id);
      } else {
        socket.emit('assign_trophy', id, assigningRound);
      }
    }
  }

  let topText = () => {
    switch (assigningRound) {
      case Gold:
        return 'Select the best photo to get the gold trophy!';
      case Silver:
        return 'Select the second best photo to get the silver trophy!';
      case Bronze:
        return 'Select the third best photo to get the bronze trophy!';
      case Trash:
        return 'If you want, select the worst photo to give it a poop emoji!';
      default:
        return 'Hang tight until everyone else has judged their photos!';
    }
  }

  let getAwardStatusPhoto = (awardStatus: string | undefined | null) => {
    if (!awardStatus) return '';
    switch (awardStatus) {
      case Gold:
        return '/gold_trophy.jpg';
      case Silver:
        return '/silver_trophy.jpg';
      case Bronze:
        return '/bronze_trophy.jpg';
      case Trash:
        return '/poop_trophy.jpg';
      default:
        return '';
    }
  }

  useEffect(() => {
    let trophies = [] as string[];
    gameState.submissionsToMyPictures.forEach(p => {
      p.submittedPictures.forEach(s => {
        if (s.awardStatus) {
          trophies.push(s.awardStatus);
        }
      });
    });
    let numToAssign: string | undefined = undefined;
    [Gold, Silver, Bronze, Trash].forEach((i) => {
      if (!numToAssign && !trophies.includes(i)) {
        numToAssign = i;
      }
    });

    if (!numToAssign) {
      setAssigningRound(null);
    } else {
      let totalPhotos = gameState.submissionsToMyPictures.reduce((prev, curr) => prev + curr.submittedPictures.length, 0);
      let numNeeded = {
        [Gold]: 1,
        [Silver]: 2,
        [Bronze]: 3,
        [Trash]: 4
      };

      if (totalPhotos < numNeeded[numToAssign]) {
        setAssigningRound(null);
      } else {
        setAssigningRound(numToAssign);
      }
    }
  }, [gameState.submissionsToMyPictures]);

  let PrintJudgingPicture = ({ pic }: { pic: JudgingPicture }) => {
    return (
      <div className="flex flex-col items-center px-5 sm:px-10 py-5">
        <MediumBoxPicture
          onClick={() => {
            setViewingPhoto({
              url: pic.url,
              collectedBy: `${userName} (You)`,
              collectedByPictureUrl: userPicture,
              collectedByLabel: "Collected by:"
            })
          }}
          src={pic.thumbnailUrl || pic.url}
        />

        {pic.submittedPictures.length === 0 && (
          <h1 className="text-center text-xl md:text-2xl text-gray-900 mt-5">No one could find this one! :&#40;</h1>
        )}
        <div className="grid grid-cols-2 px-2 mt-5 w-full">
          {pic.submittedPictures.map((submission) => (
            <SmallBoxPicture
              key={submission.id}
              src={submission.thumbnailUrl || submission.url}
              onClick={() => {
                setViewingPhoto({
                  url: submission.url,
                  collectedBy: "[REDACTED]",
                  collectedByPictureUrl: "https://pic-and-split.nyc3.cdn.digitaloceanspaces.com/pic-and-split-prod/default-avatars/unknown.png",
                  collectedByLabel: "Submitted by:"
                })
              }}

              topRight={submission.awardStatus ? <img
                src={getAwardStatusPhoto(submission.awardStatus)}
                className="h-full w-full rounded-full"
                alt={`${submission.awardStatus} trophy`}
              /> : <span></span>}
              topRightOnClick={() => assignTrophy(submission.id)}
              topRightClassName="h-10 w-10 md:h-14 md:w-14 border-2 border-primary-300"
            />
          ))}
        </div>
      </div>
    );
  }

  if (showInstructions) {
    return (
      <InstructionsScreenModal open={showInstructions} onClose={() => setShowInstructions(false)} title="Round Three!" showSoundsGood={true} subtitle={instructionsText}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Round Three - Pic&amp;Split</title>
        </Helmet>
      </InstructionsScreenModal>
    );
  }

  return (
    <GameBorder background="hamburger-background" foreground="hamburger-foreground" logo={false}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Round Three - Pic&amp;Split</title>
      </Helmet>
      <ViewPhotoModal
        open={!!viewingPhoto}
        onClose={() => setViewingPhoto(undefined)}
        imgSrc={viewingPhoto?.url || ""}
        collectedBy={viewingPhoto?.collectedBy}
        collectedByPictureUrl={viewingPhoto?.collectedByPictureUrl}
        collectedByLabel={viewingPhoto?.collectedByLabel}
      />
      <JudgingPicturesTimerBar gameState={gameState} assigningRound={assigningRound} trophyPicture={getAwardStatusPhoto(assigningRound)} />
      <img src="/picAndSplitLogoVerticalBlack.png" alt="Pic&amp;Split" className="mt-5 max-h-28 md:max-h-40 mx-auto" />

      <h3 className="text-5xl md:text-6xl pt-5 font-cursive text-center text-gray-900">Round Three</h3>

      <div className={"text-2xl sm:text-3xl w-full text-center px-3"}>{topText()}</div>
      {gameState.submissionsToMyPictures.map(p => <PrintJudgingPicture key={p.id} pic={p} />)}
    </GameBorder>
  );
}

export default JudgingPictures;
