import Resizer from 'react-image-file-resizer';

const resizeFile = (file: Blob, maxSize = 1080): Promise<File> =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      maxSize, // maxWidth
      maxSize, // maxHeight
      'JPEG', // compressFormat
      100, // quality of resize
      0, // rotation
      uri => {
        // we know this is a file since we set the output type as file
        resolve(uri as File);
      }, // callback
      'file' // outputType
    );
  });

export default resizeFile;
