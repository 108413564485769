import { useState, useEffect, useContext, useReducer } from 'react';
import io, { ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import GameStateUpdate from '../models/GameStateDelta';
import GameState, { GameStatus } from '../models/GameState';
import { PlayerUIDContext } from '../providers/PlayerUidProvider';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import GameBorder from '../components/GameBorder';
import NameAvatar from '../gameComponents/NameAvatar';
import Lobby from '../gameComponents/Lobby';
import CollectingPictures from '../gameComponents/CollectingPictures';
import SubmittingPictures from '../gameComponents/SubmittingPictures';
import HighScoresUpdate from '../gameComponents/HighScoresUpdate';
import JudgingPictures from '../gameComponents/JudgingPictures';
import { MessageProviderContext, MessageType } from '../providers/MessageProvider';
import { Helmet } from 'react-helmet';
import { noteError } from '../utils/logger';
import gameStateReducer, { initialGameState } from '../reducers/gameStateReducer';
import ReconnectingModal from '../components/modals/ReconnectingModal';
import AdminControls from '../components/AdminControls';
import BottomTimer from '../components/BottomTimer';
import ReturnToGroupComponent from '../gameComponents/ReturnToGroup';

type SocketHeaders = Partial<ManagerOptions & SocketOptions> & {
  query: {
    uid: string;
    name: string;
    pictureUrl: string;
    gameCode: string;
  };
};

function Game() {
  let [editingInfo, setEditingInfo] = useState(true);
  let [socket, setSocket] = useState<Socket>();
  const { addMessage, clearMessages } = useContext(MessageProviderContext);
  let [gameState, dispatch] = useReducer(gameStateReducer, initialGameState);
  let [reconnecting, setReconnecting] = useState(false);

  let uidContext = useContext(PlayerUIDContext);
  let { gameCode } = useParams();
  let {
    LobbyWaiting,
    LobbyCountdown,
    RoundOne,
    PostRoundOneHighScores,
    RoundTwo,
    PostRoundTwoHighScores,
    RoundThree,
    ReturnToGroup,
    FinalHighScores,
  } = GameStatus;
  const navigate = useNavigate();

  useEffect(() => {
    if (socket) {
      socket.emit('set_ready', !editingInfo);
    }
  }, [socket, editingInfo]);

  useEffect(() => {
    if (!gameCode) {
      socket?.disconnect();
      navigate('/joinGame');
      return;
    } else if (uidContext) {
      try {
        if (socket) {
          socket.connect();
        } else {
          const tempSocket = io('/picAndSplitSocket', {
            query: {
              uid: uidContext,
              gameCode: gameCode.trim().toUpperCase(),
            },
          } as SocketHeaders);
          setSocket(tempSocket);
        }

        return () => {
          socket?.disconnect();
        };
      } catch (e: any) {
        addMessage('Error connecting to server');
        noteError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uidContext, gameCode, navigate, socket]);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        setEditingInfo(true);
        clearMessages();
        setReconnecting(false);
      });

      socket.on('game_state_concrete', (state: GameState) => {
        dispatch({ type: 'game_state_concrete', payload: state });
      });

      socket.on('game_state_delta', (state: GameStateUpdate) => {
        dispatch({ type: 'game_state_delta', payload: state });
      });

      socket.on('connect_error', () => {
        addMessage('Error connecting to server');
        noteError('Error connecting to server');
      });

      socket.on('error', (error: string) => {
        addMessage(error);
        noteError(error);
      });

      socket.on('message', (message: string) => {
        addMessage(message, MessageType.INFO);
      });

      socket.on('warning', (warning: string) => {
        addMessage(warning, MessageType.WARNING);
      });

      socket.io.on('reconnect_attempt', () => {
        addMessage('Trying to reconnect...', MessageType.INFO);
        setReconnecting(true);
      });

      socket.io.on('reconnect_error', e => {
        setReconnecting(true);
      });

      socket.io.on('reconnect_failed', () => {
        addMessage('Failed to reconnect to the game :(');
        socket?.disconnect();
        navigate(`/joinGame/${gameCode}`);
      });

      socket.io.on('reconnect', () => {
        socket?.emit('request_concrete');
        clearMessages();
        setReconnecting(false);
      });

      socket.on('disconnect', (reason: string) => {
        if (reason === 'io server disconnect') {
          clearMessages();
          navigate(`/joinGame/${gameCode}`, { replace: true });
        } else if (!gameState || gameState.gameStatus !== FinalHighScores) {
          setReconnecting(true);
          clearMessages();
          addMessage('Disconnected from server', MessageType.INFO);
        }
      });
    }
    return () => {
      if (socket) {
        ['connect', 'game_state_concrete', 'game_state_delta', 'connect_error', 'error', 'disconnect'].forEach(s =>
          socket?.off(s)
        );
        ['reconnect_attempt', 'reconnect_error', 'reconnect_failed', 'reconnect'].forEach(s => socket?.off(s));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, gameState, gameCode, navigate]);

  useEffect(() => {
    const alertUser = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  useEffect(() => {
    let doneEditing = localStorage.getItem('picAndSplitDoneEditing');
    console.log('Got editing: ', doneEditing);
    if (doneEditing) {
      console.log('set editing to false');
      setEditingInfo(false);
    }
  }, []);

  let generateGameChild = () => {
    if (!gameCode || !gameState) {
      return (
        <GameBorder
          foreground='anchor-foreground'
          background='anchor-background'
        >
          <Helmet>
            <meta charSet='utf-8' />
            <title>Loading - Pic&amp;Split</title>
          </Helmet>
          <h3 className='text-6xl pt-5 font-cursive text-center text-gray-900'>Loading...</h3>
        </GameBorder>
      );
    } else if (gameState.gameStatus === FinalHighScores) {
      socket?.disconnect();
      navigate(`/highScores/${gameCode}?showTrophies=true`, { replace: true });
      return <Navigate to={`/highScores/${gameCode}?showTrophies=true`} />;
    } else if (editingInfo) {
      return (
        <NameAvatar
          gameState={gameState}
          socket={socket}
          setEditingInfo={setEditingInfo}
        />
      );
    } else {
      switch (gameState.gameStatus) {
        case LobbyWaiting:
          return (
            <Lobby
              gameState={gameState}
              setEditingInfo={setEditingInfo}
              socket={socket}
            />
          );
        case LobbyCountdown:
          return (
            <Lobby
              gameState={gameState}
              setEditingInfo={setEditingInfo}
              socket={socket}
            />
          );
        case RoundOne:
          return (
            <CollectingPictures
              gameState={gameState}
              socket={socket}
            />
          );
        case PostRoundOneHighScores:
        case PostRoundTwoHighScores:
          return <HighScoresUpdate gameState={gameState} />;
        case RoundTwo:
          return (
            <SubmittingPictures
              gameState={gameState}
              socket={socket}
            />
          );
        case RoundThree:
          return (
            <JudgingPictures
              gameState={gameState}
              socket={socket}
            />
          );
        case ReturnToGroup:
          return (
            <ReturnToGroupComponent
              gameState={gameState}
              socket={socket}
            />
          );
        default:
          return (
            <GameBorder
              foreground='anchor-foreground'
              background='anchor-background'
            >
              <Helmet>
                <meta charSet='utf-8' />
                <title>Error - Pic&amp;Split</title>
              </Helmet>
              <h3 className='text-6xl pt-5 font-cursive text-center text-gray-900'>Error</h3>
              <p>{gameState.gameStatus}</p>
            </GameBorder>
          );
      }
    }
  };

  return (
    <>
      {generateGameChild()}
      <AdminControls
        gameState={gameState}
        socket={socket}
      />
      <BottomTimer gameState={gameState} />
      <ReconnectingModal open={reconnecting} />
    </>
  );
}

export default Game;
